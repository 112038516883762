@import '../responsive';

.wa-breadcrumb {
  list-style: none;
  padding-left: 0;
  font-size: 16px;
  color: $primary;
  display: flex;
  font-weight: $fw-regular;
  margin: 0;

  @include respond(phone) {
    flex-wrap: wrap;
    row-gap: 5px;
  }

  mat-icon {
    font-size: calc(#{$breadcrumb-fs} * 1.25);
    height: calc(#{$breadcrumb-fs} * 1.25);
    width: calc(#{$breadcrumb-fs} * 1.25);
    // line-height: calc(#{$breadcrumb-fs} * 1.25);
  }

  li {
    font-size: $breadcrumb-fs;
    position: relative;
    display: flex;

    span {
      vertical-align: middle;
    }

    &:not(:last-child) {
      padding-right: 25px;
      cursor: pointer;

      &:after {
        position: absolute;
        top: 3px;
        right: 5px;
        content: '\e409';
        font-family: 'Material Symbols Outlined';
        font-weight: normal;
        font-style: normal;
        font-size: 16px;
        line-height: 1;
        letter-spacing: normal;
        text-transform: none;
        display: inline-block;
        white-space: nowrap;
        word-wrap: normal;
        direction: ltr;
        -webkit-font-feature-settings: 'liga';
        -webkit-font-smoothing: antialiased;
        color: $text-lighter;
      }
    }

    &:last-child {
      color: $text-light;
    }
  }
}

.wa-breadcrumb-dialog {
  list-style: none;
  padding-left: 0;
  font-size: 16px;
  color: $text-light;
  display: flex;
  font-weight: $fw-regular;
  margin: 0;

  @include respond(phone) {
    flex-wrap: wrap;
    row-gap: 5px;
    margin-bottom: 8px;
  }

  mat-icon {
    font-size: 20px;
    height: 20px;
    width: 20px;
  }

  li {
    font-size: 14px;
    position: relative;

    span {
      vertical-align: middle;
    }

    &:not(:last-child) {
      padding-right: 25px;
      cursor: pointer;

      &:after {
        position: absolute;
        top: 3px;
        right: 5px;
        content: '\e409';
        font-family: 'Material Symbols Outlined';
        font-weight: normal;
        font-style: normal;
        font-size: 16px;
        line-height: 1;
        letter-spacing: normal;
        text-transform: none;
        display: inline-block;
        white-space: nowrap;
        word-wrap: normal;
        direction: ltr;
        -webkit-font-feature-settings: 'liga';
        -webkit-font-smoothing: antialiased;
        color: $text-lighter;
      }
    }

    &:last-child {
      color: $text-light;
    }
  }
}

.question-of-the-day-icon {

  .text-disabled {
    padding-left: 24px;
    position: relative;

    &:after {
      content: '\f59a';
      font-family: "Material Symbols Outlined";
      font-size: 22px;
      position: absolute;
      left: 0;
      color: #666;
    }
  }
}
