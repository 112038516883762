@import 'src/styles/breakpoints';

.step-description {
  width: 100%;
  text-align: center;
  margin-bottom: 41px;
  font-size: 40px !important;
  font-weight: $fw-regular !important;
  line-height: 48px !important;
}

@media (max-width: $breakpoint--tablet) {
  .step-description {
    font-style: normal !important;
    font-weight: $fw-regular !important;
    font-size: 22px !important;
    line-height: 27px !important;
  }
}

.mat-form-field.mat-form-field-invalid .mat-error {
  line-height: 1.6;
}

// Error message
.mat-form-field .mat-form-field-wrapper .mat-form-field-subscript-wrapper mat-error {
  margin-bottom: 5px;
}

.mat-form-field.mat-form-field-invalid .mat-error:before {
  margin-top: 2px;
}

.mat-input-custom-label {
  font-size: 9.9px !important;
  margin-bottom: 0px !important;
  color: #666 !important;
}