.mat-menu-trigger {
  height: auto !important;

  &[mat-menu-trigger-custom] {
    padding-right: 15px !important;
    width: auto !important;

    &:after {
      @at-root mat-nav-list & {
        display: none;
      }

      content: '';
      width: 4px;
      height: 4px;
      border-top: 2px solid $gray-3;
      border-left: 2px solid $gray-3;
      display: block;
      transform: rotate(225deg) translateX(50%);
      position: absolute;
      right: 0;
      top: calc(50% - 2px);
    }

    &.mat-icon-button:after {
      top: 50%;
    }
  }
  &.mat-list-item-with-avatar {
    height: 56px !important;
  }

  .mat-button-wrapper {
    display: block !important;

    img {
      width: 30px;
      height: 30px;
    }
  }
}

.mat-menu-item {
  img {
    margin-right: 8px;
  }
}

.mat-select-panel-wrap {
  input {
    width: 100%;
    height: 50px;
    border: 0;
    padding: 15px;
    box-sizing: border-box;

    &:focus {
      outline-color: $primary;
    }
  }
}
