.mat-icon {
  &.mat-icon-rounded-bg {
    background-color: $primary;
    border-radius: 100%;
    width: $table-case-icon;
    height: $table-case-icon;
    font-size: $table-case-icon-fs;
    line-height: $table-case-icon;
    color: #fff;
    text-align: center;
    vertical-align: middle;
    margin-right: 5px;

    &.mat-icon-inactive {
      background-color: $background-grey;
    }

    svg {
      width: 50%;
    }
  }
}

.feed {
  &-post {
    &.mat-form-field .mat-form-field-flex {
      height: auto;
      min-height: 50px;
      padding: 0 !important;

      textarea {
        line-height: 22px !important;
      }

      .mat-form-field-label {
        padding: 4px 22px;
      }

      .mat-form-field-infix {
        padding: 10px !important;
      }
    }

    &-option {
      .mat-select {
        &-trigger {
          height: auto;
        }

        &-value {
          display: inline;
          width: auto;
        }
      }
    }
  }

  &-listed {
    mat-card-content {
      padding: 0 24px 0 67px;
      margin-bottom: 0;

      @include respond(phone) {
        padding-left: 1.5rem !important;
      }
    }

    mat-card-actions {
      padding-left: 76px !important;

      @include respond(phone) {
        padding-left: 1.5rem !important;
      }
    }
  }

  &-text {
    color: $text-black !important;
    white-space: pre-line;
  }

  &-text-button {
    &.mat-button-base {
      background-color: transparent !important;
      padding-left: 0 !important;
      padding-right: 0 !important;
      color: $text-dark !important;
      vertical-align: middle;

      &:hover {
        background-color: transparent !important;
        color: $primary !important;
      }
    }

    .mat-button-focus-overlay {
      opacity: 0 !important;
    }

    .mat-icon {
      font-size: 20px;
      height: auto;
    }

    &.feed-button-image .mat-icon {
      color: #1c84ff;
    }

    &.feed-button-file .mat-icon {
      color: $orange-1;
    }

    &.feed-button-doc .mat-icon {
      color: $primary;
    }

    &:hover .mat-icon {
      color: $primary !important;
    }
  }

  &-comment {
    border-radius: 50px;
    background-color: #eff4fa !important;
    line-height: 50px;
    height: 50px;
    padding: 0 20px !important;

    &.mat-input-element::-webkit-input-placeholder {
      color: #666 !important;
    }

    @include ratio-125 {
      line-height: calc(50px - #{$ratio-125-value});
      height: calc(50px - #{$ratio-125-value});
    }
  }

  &-attachment {
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem !important;
    flex-wrap: wrap;

    &.screenshot {
      gap: 1.4rem;
      justify-content: center;

      .feed-attachment-img {
        border: 1px solid $border-color-1;
      }
    }

    &-upload {
      border: 1px dashed $primary !important;
      flex-flow: column;

      &:hover {
        background: rgba($primary, 0.1);
      }
    }

    &-file {
      font-size: 24px;

      +span {
        font-size: 10px;
        text-align: center;
        line-height: 12px;
        margin-top: 5px;
      }
    }

    &-remove {
      position: absolute;
      font-size: 16px;
      top: -10px;
      right: -10px;
      background-color: #fff;
      padding: 5px;
      border-radius: 50%;
      width: 20px !important;
      height: 20px !important;
      display: flex !important;
      justify-content: center;
      align-items: center;
      color: $danger !important;
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.06);

      &:hover {
        color: #fff !important;
        background: $danger;
      }
    }

    &-name {
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 10px;
    }

    >div {
      cursor: pointer;
      position: relative;
      margin-bottom: 16px;
    }

    &-docs {
      background-color: rgba($primary, 0.1);

      .mat-icon {
        color: $primary;
      }
    }

    &-zip {
      background-color: rgba($orange-1, 0.1);

      .mat-icon {
        color: $orange-1;
      }
    }

    &-zip,
    &-docs,
    &-img,
    &-case-icon {
      font-size: 40px;
      width: 80px;
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 6px;

      .feed-attached-icon:after {
        content: '';
        width: 40px;
        height: 40px;
        display: block;
        background-size: 40px;
        background-repeat: no-repeat;
      }

      &[data-type='docs'] {
        mat-icon {
          display: none;
        }

        background-color: rgba(227, 24, 24, 0.1);

        .feed-attached-icon:after {
          content: '';
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='10' fill='rgb(227, 24, 24)' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5ZM1.6 11.85H0v3.999h.791v-1.342h.803c.287 0 .531-.057.732-.173.203-.117.358-.275.463-.474a1.42 1.42 0 0 0 .161-.677c0-.25-.053-.476-.158-.677a1.176 1.176 0 0 0-.46-.477c-.2-.12-.443-.179-.732-.179Zm.545 1.333a.795.795 0 0 1-.085.38.574.574 0 0 1-.238.241.794.794 0 0 1-.375.082H.788V12.48h.66c.218 0 .389.06.512.181.123.122.185.296.185.522Zm1.217-1.333v3.999h1.46c.401 0 .734-.08.998-.237a1.45 1.45 0 0 0 .595-.689c.13-.3.196-.662.196-1.084 0-.42-.065-.778-.196-1.075a1.426 1.426 0 0 0-.589-.68c-.264-.156-.599-.234-1.005-.234H3.362Zm.791.645h.563c.248 0 .45.05.609.152a.89.89 0 0 1 .354.454c.079.201.118.452.118.753a2.3 2.3 0 0 1-.068.592 1.14 1.14 0 0 1-.196.422.8.8 0 0 1-.334.252 1.298 1.298 0 0 1-.483.082h-.563v-2.707Zm3.743 1.763v1.591h-.79V11.85h2.548v.653H7.896v1.117h1.606v.638H7.896Z'/%3E%3C/svg%3E");
        }
      }

      .mat-icon {
        width: auto;
        height: auto;
      }
    }

    img {
      object-fit: cover;
    }
  }

  &-timestamp {
    font-size: 12px;
  }

  &-commented {
    background-color: $bg-light;
    padding: 1rem;
    border-radius: 6px;
    width: 100%;

    &-user {
      margin-bottom: 10px;
    }

    &-username {
      font-weight: $fw-regular;
    }

    &-timestamp {
      font-size: 12px;
    }

    &-text {
      font-size: 15px;
      line-height: 1.5;
    }
  }
}

wr-feed-card,
wr-preview-content,
wr-edit-content {
  .cursor-pointer:hover {
    text-decoration: underline;
  }

  .avatar:not(.avatar-sm) {
    min-width: $avatar-size;
  }

  .mat-card-header {
    .mat-card-title {
      font-size: 14px;
    }

    .mat-card-subtitle {
      font-size: 12px !important;

      >.d-flex {
        width: 90px;
      }

      &:not(:first-child) {
        margin-top: -12px;
      }

      mat-icon {
        width: 20px !important;
        height: 20px !important;
      }

      .mat-select-trigger .mat-select-arrow-wrapper {
        top: -2px;
        transform: scale(0.5);
      }
    }
  }

  .mat-select {
    height: auto;

    .mat-select-value {
      font-size: 10px !important;
      line-height: 10px !important;
    }
  }

  .mat-button-base.mat-icon-button,
  .mat-button-base.mat-icon-button:not(.mat-menu-trigger):hover {
    background-color: $primary !important;

    .mat-icon {
      color: $white !important;
    }
  }

  .mat-icon-button .mat-button-wrapper {
    font-weight: 300 !important;
    font-size: 22px !important;
  }
}

.text-orange {
  color: $orange-1 !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.fixed-div {
  padding: 15px;
  margin: -15px;
}

wr-access-card,
wr-forms-list-card {
  .card-head {
    .mat-icon-button:not(.text-primary) .mat-icon {
      color: $gray-3 !important;
    }

    .mat-icon {
      font-size: 20px;
    }
  }
}

wr-forms-list-card-list-item {
  padding: 0 1rem;
  display: block;

  &+wr-forms-list-card-list-item {
    padding: 1rem 1rem 0;
    margin-top: 1rem;
    border-top: 1px solid $bg-light;
  }
}

.mat-select-feed-privacy {
  .mat-option .mat-icon {
    margin-right: 0;
  }
}

.list-item__container_header {
  padding: 5px 1rem;
  // background-color: $bg-light;
  background-color: rgba(102, 102, 102, 0.05);

  //text-transform: uppercase;
  font-size: 12px;
  font-weight: $fw-bold;

  &.header-orange {
    background-color: rgba($orange-1, 0.05);
    color: $orange-1;
  }
}

.case-access-form-body {
  max-width: 400px;
  margin: 30px auto;
}

.feed-attachment-file-icon {
  font-size: 40px;
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;

  .feed-attached-icon:after {
    content: '';
    width: 40px;
    height: 40px;
    display: block;
    background-size: 40px;
    background-repeat: no-repeat;
  }

  &[data-type='pdf'] {
    background-color: rgba(227, 24, 24, 0.1);

    .feed-attached-icon:after {
      content: '';
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='10' fill='rgb(227, 24, 24)' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5ZM1.6 11.85H0v3.999h.791v-1.342h.803c.287 0 .531-.057.732-.173.203-.117.358-.275.463-.474a1.42 1.42 0 0 0 .161-.677c0-.25-.053-.476-.158-.677a1.176 1.176 0 0 0-.46-.477c-.2-.12-.443-.179-.732-.179Zm.545 1.333a.795.795 0 0 1-.085.38.574.574 0 0 1-.238.241.794.794 0 0 1-.375.082H.788V12.48h.66c.218 0 .389.06.512.181.123.122.185.296.185.522Zm1.217-1.333v3.999h1.46c.401 0 .734-.08.998-.237a1.45 1.45 0 0 0 .595-.689c.13-.3.196-.662.196-1.084 0-.42-.065-.778-.196-1.075a1.426 1.426 0 0 0-.589-.68c-.264-.156-.599-.234-1.005-.234H3.362Zm.791.645h.563c.248 0 .45.05.609.152a.89.89 0 0 1 .354.454c.079.201.118.452.118.753a2.3 2.3 0 0 1-.068.592 1.14 1.14 0 0 1-.196.422.8.8 0 0 1-.334.252 1.298 1.298 0 0 1-.483.082h-.563v-2.707Zm3.743 1.763v1.591h-.79V11.85h2.548v.653H7.896v1.117h1.606v.638H7.896Z'/%3E%3C/svg%3E");
    }
  }

  &[data-type='xls'],
  &[data-type='xlsx'] {
    background-color: rgba(31, 110, 67, 0.1);

    .feed-attached-icon:after {
      content: '';
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='rgb(31 110 67)' class='bi bi-file-earmark-excel' viewBox='0 0 16 16'%3E%3Cpath d='M5.884 6.68a.5.5 0 1 0-.768.64L7.349 10l-2.233 2.68a.5.5 0 0 0 .768.64L8 10.781l2.116 2.54a.5.5 0 0 0 .768-.641L8.651 10l2.233-2.68a.5.5 0 0 0-.768-.64L8 9.219l-2.116-2.54z'/%3E%3Cpath d='M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z'/%3E%3C/svg%3E%0A");
    }
  }

  .mat-icon {
    width: auto;
    height: auto;
  }
}

.animated-rotation {
  animation: rotation 3s infinite;
}

.leader-mode {
  .hide-when-leader {
    display: none !important;
  }
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}
