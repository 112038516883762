.avatar {
  width: $avatar-size;
  height: $avatar-size;

  line-height: $avatar-size;
  text-align: center;
  font-size: 12px;
  font-weight: $fw-bold;
  background-color: $primary;
  color: #fff;
  text-transform: uppercase;
  border-radius: 50%;
  display: inline-block;
  margin-right: 0;
  position: relative;

  &-sm,
  &-overlap {
    width: calc(#{$avatar-size} - 5px);
    height: calc(#{$avatar-size} - 5px);
    line-height: calc(#{$avatar-size} - 5px);

    figcaption,
    figure {
      width: calc(#{$avatar-size} - 5px) !important;
      height: calc(#{$avatar-size} - 5px) !important;
      line-height: calc(#{$avatar-size} - 5px);
      font-size: 9px;
    }
  }

  &-overlap {
    border: 2px solid #fff;

    &+& {
      margin-left: -15px;
      z-index: 1;
    }
  }

  figure {
    z-index: 1;

    &,
    figcaption {
      display: inline-block;
      width: $avatar-size;
      height: $avatar-size;
      background-size: cover;
      border-radius: 100%;
      position: absolute;
      top: 0;
      left: 0;
      margin: 0;
      line-height: calc(#{$avatar-size} - 10px);
    }
  }

  &-table {
    // background-color: transparent;
    vertical-align: middle;
    margin-right: 5px;

    img {
      width: $avatar-size;
      height: $avatar-size;
      border-radius: 100%;
      margin-right: 10px;
    }
  }

  &-profile {
    width: 100px;
    height: 100px;

    figure,
    figcaption {
      width: 100px;
      height: 100px;
      line-height: 100px;
      font-size: 40px;
    }

    &-sm {
      width: 54px;
      height: 54px;

      figure,
      figcaption {
        width: 54px;
        height: 54px;
        line-height: 54px;
        font-size: 20px;
      }
    }

    &-edit {
      width: 32px !important;
      height: 32px !important;
      min-width: 32px !important;
      padding: 0 !important;
      position: absolute !important;
      bottom: -2px;
      right: 5px;
      z-index: 9;
      line-height: 32px !important;
      // opacity: 0;
      // display: none !important;
      background: $primary !important;

      mat-icon {
        color: #fff;

      }
    }
  }

  &-login {
    width: 60px;
    height: 60px;

    figure,
    figcaption {
      width: 60px;
      height: 60px;
      line-height: 60px;
      font-size: 24px;
    }
  }

  &-location {
    width: 40px;
    height: 40px;
    min-width: 40px;
    min-height: 40px;
    line-height: 40px;
    font-size: 20px;
  }

  &-light {
    background: $gray-3;
  }

  &-setup {
    width: 48px;
    min-width: 48px;
    height: 48px;
    margin-right: 10px;

    figure,
    figcaption {
      width: 48px;
      height: 48px;
      line-height: 48px;
    }
  }
}

.avatar.avatar-profile:hover .avatar-profile-edit {
  display: block !important;
  background-color: $primary !important;

  .mat-icon {
    color: #fff;
  }
}

.navbar-profile-dropdown.mat-menu-panel {
  min-width: 275px;
}