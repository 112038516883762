@import 'src/styles/_theme.scss';
@import 'src/styles/responsive';
$chat-radius: 10px;

.mat-chat {
  @at-root .mat-chat-question,
    & {
    padding: 0.5rem 1rem;
    border-radius: 0 $chat-radius $chat-radius 0;
    background: $bg-light;
    max-width: 600px;
    box-sizing: border-box;
  }

  width: 100%;
  cursor: pointer;
  border: 1px solid $border-color-1;
  font-size: 1rem;

  &-container {
    max-width: 800px;
    width: calc(100% - 100px);

    @include respond(phone) {
      width: 100%;
    }
  }

  &-question {
    border-bottom-left-radius: $chat-radius;
    padding: 1rem;
    font-size: 1rem;
  }

  &-context {
    &-container {
      padding: 1rem;
      border-radius: 0 $chat-radius $chat-radius;
      display: inline-flex;
      flex-flow: column;
      color: $text-dark;
      border-right: 5px solid rgba($primary, 0.5);
      gap: 5px;
    }

    &-text {
      font-size: 1rem;
      line-height: 1.6;
    }
  }

  &-option {
    padding: 1.5rem;
    border-radius: $chat-radius;

    &.option {
      &-primary {
        background-color: rgba($primary, 0.1);
        border-left: 5px solid rgba($primary, 0.7);
      }

      &-orange {
        background-color: rgba($orange-1, 0.1);
        border-left: 5px solid rgba($orange-1, 0.7);
      }
    }
  }

  &:first-child {
    border-radius: $chat-radius $chat-radius $chat-radius 0;
  }

  &:last-child {
    border-radius: 0 $chat-radius $chat-radius;
  }

  &-response-block {
    .mat-chat {
      border-radius: $chat-radius 0 $chat-radius $chat-radius;
    }
  }

  &.mat-chat-active {
    font-weight: 600;
  }

  &.mat-chat-active,
  &:not(.disabled):hover {
    background: $primary;
    border-color: $primary;
    color: #fff;
  }

  &.disabled {
    cursor: auto;
  }

  &-link {
    position: relative;
    padding: 10px 10px 0 40px;
    margin-top: 1rem;
    border-top: 1px solid rgba($primary, 0.1);
    width: calc(100% + 2rem);
    box-sizing: border-box;
    margin-left: -1rem;
    margin-right: -1rem;
    display: flex;
    align-items: center;

    a,
    span {
      margin-left: 10px;
      font-size: 14px;
      color: $text-light !important;
    }

    mat-icon {
      font-size: 16px;
      vertical-align: middle;
      line-height: 24px;
      color: $primary;
      position: absolute;
      left: 16px;
    }
  }
}

.mat-button-chat-topic {
  height: auto !important;
  min-height: 100px;

  &.mat-button-base {
    background: rgba($primary, 0.1) !important;
    border: 1px solid rgba($primary, 0.2) !important;
    transform: translate(0, 0);
    transition: 0.2s ease-in-out;
    box-shadow: 0 5px 10px rgba($primary, 0) !important;
    flex-grow: 1;
    flex-basis: 0%;
    max-width: 300px;
    min-width: 250px;
    min-height: 100px;

    &:hover {
      transform: translate(0, -2.5px);
      box-shadow: 0 5px 10px rgba($primary, 0.1) !important;
      background: rgba($primary, 0.1) !important;
      border: 1px solid rgba($primary, 0.2) !important;
    }
  }

  @include respond(phone) {
    max-width: 100%;
    min-width: 100% !important;
    padding: 10px !important;
    margin-bottom: 0.5rem !important;
    min-height: auto;
  }

  padding: 0.5rem !important;

  .mat-icon {
    width: auto;
    height: auto;
    font-size: 28px !important;
    margin-bottom: 0.5rem;
    color: $primary;
    position: relative;

    &:after {
      content: '';
      width: 80%;
      height: 4px;
      background-color: rgba($primary, 0.25);
      display: block;
      position: absolute;
      bottom: -10px;
      border-radius: 50%;
      left: 10%;
    }

    @include ratio-125 {
      @include fs_ratio-125(36px);
    }

    @include respond(phone) {
      margin-bottom: 0;
    }
  }

  span {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    flex-flow: row;
    gap: 1rem;
    white-space: normal;
    font-size: 15px;
    color: $text-light;

    @include respond(phone) {
      flex-flow: row nowrap;
      align-items: center;
      gap: 1rem;

      span.mat-button-chat-topic-text {
        padding: 0;
        width: 100%;
        text-align: left;
        justify-content: flex-start;
      }
    }

    @include ratio-125 {
      @include fs_ratio-125(15px);
    }
  }
}

.rating {
  font-weight: $fw-bold;
  color: $gray-3;
}

.next-follow-up-card.mat-icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: rgba($white, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  color: $primary;
}

.welcome-message {
  font-size: 2rem !important;

  @include ratio-125 {
    @include fs_ratio-125(2rem);
  }
}

.mat-input-history {
  &:focus-visible {
    outline: 0;
  }
}

.chat-history {
  &-container {
    box-shadow: $shadow;
    border-radius: $border-radius;
  }

  &-items {
    border-right: 1px solid #f5f5f5;

    span {
      cursor: pointer;
      padding: 10px;
      display: block;
      background-color: $bg-light;
      border-radius: $border-radius;
      color: $text-light;
      margin-bottom: 0.5rem;
      font-weight: $fw-regular;

      &:hover {
        background: $primary;
        color: #fff;
      }
    }
  }

  &-detail {
    wr-chat-item {
      margin-bottom: 2rem;
      display: block;
      pointer-events: none !important;
    }

    .mat-chat {
      &-question {
        padding-top: 0.75rem;
        padding-bottom: 0.75rem;
      }

      &-container {
        > .mb-3 {
          margin-bottom: 0.75rem !important;
        }
      }
    }

    &,
    .chat-history-item {
      height: calc(100vh - 290px);
      overflow: auto;
    }
  }
}

.company-info-box {
  background-color: transparent;
  padding: 17px;
  margin-top: 20px;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  width: fit-content;
  box-shadow: 0px 0px 10px rgba(0, 199, 178, 0.15);

  mat-icon {
    color: $primary;
  }
}

.chat-question-loading {
  max-width: 56px !important;
}

.chat-loading {
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #00c7b2;
  /*#5197d6*/
  border-radius: 50%;
  /*box-shadow: 0 0 2px black;*/
  animation: loading 2s infinite;
}

.chat-loading:nth-child(1) {
  animation-delay: 0.2s;
  width: 4px;
  height: 4px;
  left: -15px;
}

.chat-loading:nth-child(2) {
  animation-delay: 0.6s;
  width: 4px;
  height: 4px;
}

.chat-loading:nth-child(3) {
  animation-delay: 8s;
  width: 4px;
  height: 4px;
  right: -15px;
}

.chat-loading {
  animation-iteration-count: infinite;
  animation-timing-function: ease-in;
}

@keyframes loading {
  0% {
    transform: translateY(0px);
  }

  20% {
    transform: translateY(0px);
  }

  30% {
    transform: translateY(-8px);
  }

  40% {
    transform: translateY(5px);
  }

  50% {
    transform: translateY(-2px);
  }

  60% {
    transform: translateY(2px);
  }

  80% {
    transform: translateY(0px);
  }

  100% {
    transform: translateY(0px);
  }
}

.mat-card.workability-info-card {
  background: rgba(255, 255, 255, 0.45) !important;
  border: 1px solid $border-color-1;
  box-shadow: none !important;
}

.workability-info-card-arrow {
  position: relative;

  &:after {
    position: absolute;
    content: '';
    width: 15px;
    height: 16px;
    background-repeat: no-repeat;
    background-position: center;
    right: -11px;
    background-image: url('data:image/svg+xml,<svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4.80371 1.94938L14.0117 7.26562L4.80371 12.5819L4.80371 1.94938Z" stroke="%23D4D6D7"/><path d="M13.0588 7.26584L4.31445 12.284L4.31445 2.15332L13.0588 7.26584Z" fill="%23f6f9fc"/></svg>');
  }
}

.card-activity-stepper-button {
  position: absolute;
  right: 0.5rem;
  bottom: 0.5rem;
}

.mde-popover-below .mde-popover-direction-arrow {
  border-color: #fff transparent transparent !important;
}

.mde-popover-above .mde-popover-direction-arrow {
  border-color: transparent transparent #fff !important;
}

.width-hug {
  max-width: initial !important;
  flex-grow: 0 !important;
}
