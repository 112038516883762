@import 'angular-material/material-colors';

/* colors */

$green-1: #00c7b2;
$green-2: #06e1ca;
$primary-dark: #009989;

$orange-1: #ff861c;

$red-1: #df2a43;
$danger: $red-1;
$blue: #1C84FF;

$gray-1: #e4e4e4;
$white: #fff;
$gray-2: #ebebeb;
$gray-3: #c4c4c4;
$gray-4: #333;
$gray-5: #ecf4fc;

$background-1: #ecf4fc;
$background-2: #eff4fa;
$background-3: #e5e5e5;
$background-4: #fff;
$background-5: #e2efff;
$background-news: #f5f5f5;
$background-black: #000;
$background-white: #fff;
$background-active: #e6edf5;
$background-grey: #999999;
$background-blue: #eff4fa;

$bg-light: #eff4fa;
$bg-primary: $green-1;

$text-black: #000;
$text-dark: #111;
$text-medium: #2a2a2a;
$text-light: #666;
$text-lighter: #cacaca;
$text-white: #fff;

$border-color-1: #ddd;
$border-color-2: #afd4ff;
$border-color-3: #666666;

$box-shadow: 0 20px 30px -15px rgba(0, 199, 178, 0.4);
$btn-bg: $green-1;
$btn-bg-hover: $green-2;
/* layout */
$app-header-height: 60px;
$opened-nav-width: 300px;
$closed-nav-width: 60px;
$sidenav-company-item-height: 10vh;
$nav-list-free-space: 0px;
$nav-company-free-space: 24px;
$nav-list-space: 24px;
// $wrapper-padding-bottom: 10px;

$border-radius: 6px;
$shadow: 0px 10px 15px rgba(0, 0, 0, 0.1);
$shadow-lg: 0px 15px 20px rgba(0, 0, 0, 0.2);
$padding: 20px;
$hr-bg: rgba(66, 66, 66, 0.15);
$icon-light: $text-lighter;

//Bootstrap Overite
$primary: $green-1;
$fw-light: 400;
$fw-regular: 500;
$fw-bold: 600;
$fw-bolder: 700;
$font-weight-light: $fw-light;
$font-weight-bold: $fw-bold;
$font-weight-bolder: $fw-bolder;

$fs-xs: 0.75rem; //12px
$fs-sm: 0.875rem; //14px
$fs-base: 1rem; //16px
$fs-lg: 1.25rem; //20px
$fs-xl: 1.5rem; //24px
$fs-xxl: 1.75rem; //28px
$fs-xxxl: 2rem; // 32px

$breadcrumb-fs: $fs-sm;
$button-icon-wh: 30px;

$navbar-button-icon-wh: 25px;
$navbar-avatar-wh: $navbar-button-icon-wh;
$navbar-icon-wh: 25px;

$sidebar-icon-wh: 24px;

$input-padding--horizontal: 15px;
$input-height: 45px;
$button-height: $input-height;
$btn-padding: 20px;
$input-fs: $fs-base;

$select-option-height: $input-height;
$select-fs: $fs-sm;

$date-picker-placeholder-color: rgba(0, 0, 0, 0.42);

$table-row-h: 63px;
$table-case-icon: 30px;
$table-case-icon-fs: 20px;

$avatar-size: 30px;
$base-size: 16px;
$ratio-125-value: 0.2rem;
$ratio-125-value-percentage: 5;