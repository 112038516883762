@import 'src/styles/theme';

.mat-radio-button {
  &.button-type {
    position: relative;

    .mat-radio-label {
      background-color: $gray-5;
      border-radius: 5px;
      width: 48px;
      height: 24px;
      display: flex;
      color: black;

      .mat-radio-container {
        display: none;
      }

      .mat-radio-label-content {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        height: 100%;
        width: 100%;
      }
    }

    &.mat-radio-checked {
      .mat-radio-label {
        background-color: $green-1;
      }
    }
  }
}

.org-radio {
  img {
    transition: 0.2s ease-in-out;
    padding: 12px;
    border-radius: 6px;
  }

  &:hover,
  &.mat-radio-checked {
    img {

      background-color: #fff;
    }

    .mat-button-chat-topic-text {
      color: #fff !important;
    }
  }

  &.mat-button-chat-topic.mat-button-square {
    padding: 0 !important;

    @include media-breakpoint-down(sm) {
      min-width: 100% !important;
    }

  }

}