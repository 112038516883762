@import 'src/styles/theme';
@import 'src/styles/mixins';

.mat-select {
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;

  .mat-select-placeholder {
    height: 24px;
  }

  &-empty {

    &,
    .mat-select-placeholder {
      height: 35px;
    }
  }

  .mat-select-value {
    @include font-style(500, $select-fs, normal, $text-light, 35px);
  }
}

.mat-select-panel-wrap {
  .mat-option {
    background: $background-2;
    height: $select-option-height !important;

    &.mat-selected {
      background: $background-active !important;
    }

    &.mat-option-disabled {
      background: $background-1 !important;
      opacity: 0.6;
    }

    .mat-option-text {
      @include font-style(300, $select-fs, normal, $text-light, 24px);
    }
  }
}


.select-bg-white {

  .mat-form-field-flex,
  .mat-form-field .mat-form-field-flex .mat-form-field-infix {
    background: #fff !important;
  }

  .mat-form-field-label span {
    font-weight: 500 !important;
    font-size: 0.875rem !important;
    color: rgba(0, 0, 0, 0.42) !important;
  }
}

.row-input-grid {

  .mat-form-field-infix,
  .mat-form-field {
    width: 100%;
  }
}