@import '../theme';

.thumbnail-template {
  width: 70px;
  border-radius: 4px;
  position: relative;
  display: inline-block;
  cursor: pointer;

  &-count {
    border-radius: 50%;
    min-width: 24px;
    height: 24px;
    line-height: 24px;
    background-color: #fff;
    text-align: center;
    position: absolute;
    bottom: 20px;
    right: -12px;
    font-weight: $fw-bold;
    font-size: 14px;
  }

  &-text {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 3px;
    color: #fff;
    font-size: 10px;
  }
}

.thumbnail-template-active {
  color: #009989;

  .thumbnail-template-count {
    color: $orange-1;
  }
}

.thumbnail-template {
  &-content {
    width: 70px;
    height: 45px;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml,%3Csvg width='118' height='75' viewBox='0 0 118 75' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='117.375' height='75' rx='4.45545' fill='%2300C7B2' fill-opacity='1'/%3E%3Crect opacity='1' x='13.3662' y='13.3662' width='28.2178' height='48.2673' rx='2.9703' fill='%23009989'/%3E%3Cg opacity='0.45'%3E%3Crect x='61.6328' y='28.2178' width='40.099' height='17.8218' rx='2.9703' fill='%23009989'/%3E%3Crect opacity='0.3' x='73.5146' y='51.2378' width='28.2178' height='10.396' rx='5.19802' fill='%23009989'/%3E%3Cg opacity='0.6'%3E%3Ccircle cx='53.0937' cy='18.9355' r='5.56931' fill='%23009989'/%3E%3Crect x='61.6328' y='15.0601' width='42.3745' height='7.75143' rx='3.87572' fill='%23009989'/%3E%3C/g%3E%3Ccircle opacity='0.3' cx='66.5421' cy='56.6636' r='4.90924' fill='%23009989'/%3E%3C/g%3E%3C/svg%3E%0A");
    filter: grayscale(100%);

    @at-root .thumbnail-template-active & {
      filter: grayscale(0%);
    }
  }

  &-review {
    width: 70px;
    height: 45px;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml,%3Csvg width='118' height='77' viewBox='0 0 118 77' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect y='0.633301' width='117.375' height='75.7332' rx='4.45545' fill='%23B3EEE8'/%3E%3Cg opacity='0.45'%3E%3Crect x='27' y='28.9995' width='73' height='18' rx='3' fill='%23009989'/%3E%3Crect opacity='0.3' x='41' y='52' width='59' height='11' rx='5.5' fill='%23009989'/%3E%3Cg opacity='0.6'%3E%3Ccircle cx='18.9355' cy='19.5688' r='5.56931' fill='%23009989'/%3E%3Crect x='27.4751' y='15.5688' width='72' height='8' rx='4' fill='%23009989'/%3E%3C/g%3E%3Ccircle opacity='0.3' cx='32' cy='57.9995' r='5' fill='%23009989'/%3E%3C/g%3E%3C/svg%3E%0A");
    filter: grayscale(100%);

    @at-root .thumbnail-template-active & {
      filter: grayscale(0%);
    }
  }
}

.mat-input-element-custom {
  font: inherit;
  background: transparent;
  color: currentColor;
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
  width: 100%;
  max-width: 100%;
  vertical-align: bottom;
  text-align: inherit;
  box-sizing: content-box;
}

.mat-dialog-container {
  .preview-mode {
    display: flex;
    flex-flow: column;
    max-height: 100vh;

    header {
      padding-bottom: 1rem;
      flex: 0 0 auto;
    }

    .mat-dialog-content {
      flex: 1 1 100%;
      max-height: 100%;
      padding: 0;
      overflow: hidden;
    }
  }

  .preview-page {
    position: static !important;
    overflow: hidden;
    border-radius: 6px;
    max-width: 1000px;
    min-width: 1000px;

    &-inner {
      padding: 2rem;
      max-height: calc(100vh - 300px);
      overflow: auto;
      background-color: #fff;
      border-radius: 6px;

      &-container {
        background: $bg-light;
        border: 1px solid darken($bg-light, 5%);
        box-sizing: border-box;
        border-radius: 6px;
        padding: 2rem;
      }

      // border: 30px solid #ffff;
    }
  }
}

.preview-mode {
  padding: 0;
  display: block;
  position: relative;

  .feed {
    &-comment {
      box-sizing: border-box;
    }
  }
}

wr-preview-content {
  .feed-text {
    font-size: 18px !important;
  }

  .mat-radio-group {
    display: flex;
    flex-flow: column;
  }

  .mat-radio-button:not(:last-child) {
    margin-bottom: 15px;
  }
}

.feed-comment.template-option-edit {
  border-radius: $border-radius;
}

wr-edit-content {
  .mat-button-base.mat-icon-button {
    &.btn-option-add {
      background-color: $gray-3 !important;
      color: $text-dark !important;
      cursor: pointer;
    }

    &.btn-option-close {
      // display: none;
      background-color: transparent !important;

      .mat-icon {
        color: $text-light !important;
      }

      &:hover {
        background-color: transparent !important;

        .mat-icon {
          display: inline;
          color: $danger !important;
        }
      }
    }
  }

  display: block;
  position: relative;

  &:before {
    content: '';
    height: calc(100% + 3rem);
    width: 5px;
    background-color: $primary;
    position: absolute;
    left: 0;
    top: -1.5rem;
    border-radius: 5px 0 0 5px;
  }
}

.template-option {
  min-height: 50px;
  width: 300px;
  display: inline-block;

  >div {
    position: relative;
    margin-bottom: 15px;

    &:hover {
      .btn-option-close {
        display: block;
      }
    }
  }

  button {
    margin-bottom: 0.5rem;
    position: absolute;
    left: calc(100% + 10px);
    bottom: 0;
  }
}

textarea-custom {
  border-radius: 8px;
  background-color: #eff4fa !important;
  padding: 10px 20px !important;
  box-sizing: border-box !important;
}

.template-action {
  position: absolute;
  right: 30px;
  bottom: 10px;

  .mat-icon-button {
    background: $text-light !important;
    border-radius: 50%;

    &.mat-button-base:not(.mat-menu-trigger):hover {
      background: $primary !important;
    }
  }

  .mat-icon {
    color: $white !important;
    font-size: 18px !important;
  }
}

.checkbox-custom .mat-checkbox-layout,
.radio-custom .mat-radio-label {
  position: relative;
  min-width: 250px;
  border-radius: $border-radius;
  background-color: #eff4fa !important;
  line-height: 40px;
  height: 40px;
  display: inline-flex;
  align-items: center;
  padding: 0 15px;
  transition: 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  font-weight: $fw-regular;
  color: $text-light;
  font-size: 14px;
  box-sizing: border-box;
  @include fs_ratio-125(14px);

  @include respond(phone) {
    white-space: normal;
    min-width: auto;
    height: auto;
    line-height: 1.6;
    padding: 0.5rem 0.75rem;
  }
}

.radio-custom.radio-custom-home .mat-radio-label {
  min-width: auto;
  width: 120px;
}

.checkbox-custom {
  .mat-checkbox-inner-container {
    margin: 0 8px 0 0;
    display: none;
  }

  .mat-checkbox-checked {
    .mat-checkbox-inner-container {
      display: block;
    }

    .mat-checkbox-layout {
      background-color: $primary !important;
    }

    .mat-checkbox-label {
      color: #fff !important;
    }

    &.mat-primary .mat-checkbox-background {
      background-color: transparent !important;
    }
  }

  .mat-checkbox .mat-checkbox-frame {
    background-color: transparent !important;
  }
}

.radio-custom {

  // margin-left: 10px;
  // padding-left: 10px;
  .mat-radio-outer-circle,
  .mat-radio-inner-circle,
  .mat-radio-container {
    display: none;
  }

  &.mat-radio-checked {

    .mat-radio-container,
    .mat-radio-inner-circle {
      display: block;
    }

    .mat-radio-label {
      background-color: $primary !important;
      color: $white !important;
    }

    .mat-radio-inner-circle {
      border: 4px solid $white !important;
      background: $primary;
      transform: scale(0.75);
    }
  }
}

// .template-navigation-arrow {
//   position: sticky;
//   height: 100vh;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   top: 50%;
//   width: 10%;
//   height: 50px;
//   min-width: 50px;
//   min-height: 50px;

//   margin-right: 2rem;
//   opacity: 0.25;
//   cursor: pointer;
//   .mat-icon {
//     font-size: 3rem;
//     width: 50px;
//     height: 50px;
//     line-height: 50px;
//     border-right: 1px solid #ddd;
//   }

//   &.right {
//     .mat-icon {
//       border-left: 1px solid #ddd;
//       border-right: 0;
//     }
//     margin: 0 0 0 2rem;
//   }
//   &.active,
//   &:active,
//   &:hover {
//     opacity: 1;
//     color: $primary;
//   }
// }

.mat-button-base.mat-icon-button {
  &.btn-option-change {
    background-color: rgba($orange-1, 0.35) !important;

    &:not(.mat-menu-trigger):hover {
      background-color: rgba($orange-1, 1) !important;
    }
  }

  &.btn-option-delete {
    background-color: rgba($danger, 0.5) !important;

    &:not(.mat-menu-trigger):hover {
      background-color: rgba($danger, 1) !important;
    }
  }

  &.btn-option-change,
  &.btn-option-delete {
    @at-root wr-edit-content & {
      height: 30px !important;
      width: 30px !important;
      min-width: 30px !important;
    }

    mat-icon {
      font-size: 18px;
      line-height: 20px !important;
    }
  }
}

.content-mode {
  border-radius: 0 0 8px 8px;
  padding: 0.2rem 0.5rem;
  background-color: $bg-light;
  font-size: 10px;
  color: $text-light;

  @at-root .py-2 & {
    margin-top: -0.5rem;
  }
}

.accordion-custom {
  .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled='true']) {
    background: transparent;
  }

  // .mat-expansion-panel-header,
  .mat-expansion-panel-body {
    padding-bottom: 24px !important;
  }

  .mat-expansion-panel-header {
    padding: 0 24px 0 30px;
    font-size: 15px;
    font-weight: 500;
  }

  // .mat-expansion-panel-header,
  // .mat-expansion-panel-body {
  //   padding: 0 1rem 1rem !important;
  // }

  // .mat-expansion-panel-spacing {
  //   padding-bottom: 20px !important;
  // }

  .mat-expansion-indicator:after {
    color: rgba(0, 0, 0, 0.2);
  }
}

.mat-form-field.mat-date-custom {
  .mat-form-field-flex {
    padding-left: 0.25rem !important;
  }
}

.wr-follow-up-scheduler {

  .mat-stepper-horizontal .mat-horizontal-content-container,
  .mat-dialog-content {
    padding: 0 !important;
  }
}

.text-primary-dark {
  color: darken($primary, 10%) !important;
}

.mat-dialog-container .edit-mode {
  width: 80vw;

  header {
    background: #ffff;
    padding-top: 1.5rem;
    padding-bottom: 0.5rem;
    border-radius: 6px 6px 0 0;
    width: calc(100% - 120px);
    margin: 0 auto;
  }

  .preview-page {
    max-width: 80vw;
    padding-left: 60px;
    padding-right: 60px;
  }

  .template-navigation-arrow {
    display: none;
  }

  .template-action {
    right: 5px;
  }

  .preview-page-inner {
    border-radius: 0 0 6px 6px;

    &-container {
      border: 2px dotted $primary;
      background: rgba($primary, 0.1);
    }
  }

  .thumbnail-template-text {
    color: $text-dark;
  }
}

.wr-fill-mode {
  .mat-horizontal-stepper-content {
    flex-flow: row wrap;
    gap: 2.5rem;
    padding: 0 0;

    >.mat-card {
      width: 100%;
    }
  }
}

.card-content {

  .small,
  .mat-card-subtitle {
    font-size: 11px !important;
  }

  .mat-card-title,
  textarea.mat-input-element-custom,
  .feed-comment {
    font-size: 12px !important;
  }

  .mat-card-subtitle {
    margin-top: -14px;
  }

  .feed-comment {
    height: 40px !important;
  }

  wr-preview-content,
  wr-edit-content {
    .mat-card-header {
      .mat-card-subtitle {
        font-size: 10px !important;
        margin-top: -14px;
      }
    }

    .mat-icon-button .mat-button-wrapper {
      font-size: 18px;
    }
  }

  wr-preview-content {
    .feed-text {
      font-size: 16px !important;
    }
  }

  &.feed-listed mat-card-content {
    padding-left: 75px;
  }

  .avatar,
  .mat-button-base.mat-icon-button {
    width: $avatar-size !important;
    min-width: $avatar-size !important;
    height: $avatar-size !important;
  }
}

.preview-page-inner {
  // width: 1000px;
  // max-width: 1000px;

  @include media-breakpoint-down(lg) {
    max-width: 100%;
    width: 100%;
  }
}

.content-review {
  max-width: 300px;
  position: sticky !important;
  top: -10px;
  max-height: calc(100vh - 310px) !important;
  min-height: calc(100vh - 500px) !important;
  overflow: auto;

  h4 {
    small {
      font-size: 10px;
    }
  }

  &-list {
    font-size: 12px;

    mat-icon {
      width: auto;
      height: auto;
    }

    .mat-icon {
      &.enable-icon {
        color: $primary;
      }

      &.disabled-icon {
        color: $orange;
      }
    }
  }

  .mat-menu-trigger[mat-menu-trigger-custom] {
    padding-left: 0 !important;
  }
}

.no-background.mat-button-base {

  &,
  &:hover {
    background: transparent !important;
  }

  &.text-primary {
    &:hover {
      color: $primary !important;
    }
  }

  .mat-button-focus-overlay {
    opacity: 0 !important;
  }
}

.feed-listed-width {
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
}

.badge-custom {
  h5 {
    max-width: 150px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.review-questionaire-content {
  .col-auto {
    textarea.mat-input-element.text-area-custom {
      height: calc(100vh - 425px) !important;
    }
  }

  .content-review {
    top: 16px;
    min-height: calc(100vh - 370px) !important;
    max-height: calc(100vh - 370px) !important;

    &.content-review-questionaire {
      background: transparent !important;
      box-shadow: none !important;
      padding-left: 0;
    }
  }

  .document-summary-cards__container {
    margin: 0 -1rem;
    width: auto !important;
  }

  .accordion-custom {

    .mat-expansion-panel-header,
    .mat-expansion-panel-body {
      padding: 0 16px;
    }
  }
}