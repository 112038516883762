.wa-account-card {
  max-width: 500px;
  margin: auto;
  border-radius: $border-radius;
  box-shadow: $shadow;
  padding: 30px;
  background: $white;
  overflow: hidden;

  &.card-lg {
    max-width: 80%;

    @include respond(phone) {
      max-width: 100%;
    }
  }

  p.mb-4 {
    margin-bottom: 2rem !important;
  }

  .hide-stepper-header {
    .mat-horizontal-stepper-header-container {
      display: none !important;
    }
  }

  &-title {
    text-align: center;

    .small {
      font-weight: $fw-bold;
    }

    h1 {
      font-weight: $fw-bolder;
    }
  }
}

.wa-stepper-outer {
  background: transparent;

  .mat-horizontal {
    &-content-container {
      display: none;
    }
  }
}

wr-setup,
.setup-stepper {
  background-color: $bg-light;
  display: block;
  padding: 40px $padding;
  height: 100vh;
  display: flex;
  flex-flow: column;
  box-sizing: border-box;
  overflow: auto;

  mat-horizontal-stepper {
    min-height: 40px;
  }

  .text-end.mt-n2.me-n2 {
    display: none;
  }

  .wa-account-card {
    padding: 0;

    &-title {
      padding: 30px 30px 0;
    }
  }

  mat-horizontal-stepper {
    padding: 0 30px 30px;
    overflow: hidden;
  }

  .wa-stepper-inner {
    .mat {
      &-horizontal {
        &-content-container {
          overflow: visible;
        }
      }
    }
  }
}

.icon-circle {
  width: 48px !important;
  min-width: 48px !important;
  min-height: 48px !important;
  height: 48px !important;
  border-radius: 100%;
  background-color: #d9d9d9;
  margin-right: 10px;
  display: inline-flex !important;
  align-items: center !important;
  justify-content: center !important;
  color: $white;
  font-size: 24px;
  font-weight: normal;

  &.icon-circle-sm {
    width: 30px !important;
    min-width: 30px !important;
    min-height: 30px !important;
    height: 30px !important;
    font-size: 20px;
  }
}

.custom-mat-list {
  &.mat-list-base {
    .mat-list-option {
      margin-bottom: 16px !important;
    }

    .mat-list-item,
    .mat-list-option {
      height: auto !important;
      font-size: 14px;
      font-weight: $fw-regular;
      color: $text-light;
    }

    .mat-list-item {
      .mat-list-item-content {
        padding: 16px 0 !important;
      }
    }
  }

  &-action {
    cursor: pointer;

    &.mat-list-base {
      margin: 0 -30px;
      padding: 0 30px;
      background-color: rgba($primary, 0.1);
      color: $primary;

      .mat-list-item {
        color: $primary;
        font-weight: $font-weight-bold;

        .mat-list-item-content {
          padding: 16px 0 !important;
        }
      }
    }

    .icon-circle {
      background-color: $primary;
    }
  }
}

.custom-action-onhover {
  button {
    display: none;
  }

  mat-list-item:hover button {
    display: block;
  }
}

.list-onboarding {
  max-height: calc(100vh - 472px);
}

.list-teamspace {
  max-height: calc(100vh - 572px);
  padding-right: 0.5rem;
  margin-right: -1rem;
}

.table-onboarding {
  height: calc(100vh - 472px);
  min-height: 300px;
  padding: 1rem;
  margin: 0 -1rem;

  .mat-table-custom-list-onboarding {
    th:nth-child(3),
    tr td:nth-child(3) {
      width: 150px;
    }
  }
}

wr-teamspace-profile,
wr-teamspace-invite,
.hide-stepper-header {
  .mat-horizontal-stepper-header-container {
    display: none !important;
  }
}

.mat-table-custom-list-onboarding,
.table-custom-bg {
  @include respond(phone) {
    tr.mat-row:not(.example-detail-row),
    tr.mat-footer-row {
      background: $bg-light;
    }

    td {
      background: transparent;
    }
  }

  tr {
    box-shadow: none !important;
  }

  td {
    background: $bg-light !important;

    &:first-child {
      border-radius: 50px 0 0 50px;
    }

    &:last-child {
      border-radius: 0 6px 6px 0;
    }
  }
}

.assign-person-org-wrap {
  margin: 30px auto;
  max-width: 400px;
  width: 100%;
}

.radio-hide-style {
  .radio-custom {
    padding: 4px 0 !important;
    border-radius: 4px;

    &-icon .material-icons {
      background: $primary;
      color: #fff;
    }

    &-primary-light.mat-radio-checked {
      background: rgba($primary, 0.1) !important;

      &:hover {
        background: rgba($primary, 0.2) !important;
      }

      .mat-radio-label {
        background: transparent !important;
        color: $primary !important;
      }
    }

    .mat-radio-label {
      height: 100%;
      line-height: 100%;
      min-width: auto;
      padding: 0;
      justify-content: center;

      &-content {
        display: flex;
        align-items: center;
        font-size: 11px;
        line-height: 16px;
        padding: 0 4px;
      }
    }

    .mat-radio-container {
      display: none !important;
    }
  }
}

.custom-mat-list.custom-mat-list-hover.mat-list-base
  .mat-list-item
  .mat-list-item-content {
  padding: 0.75rem !important;
  cursor: pointer;

  &:hover {
    background-color: $bg-light;
    border-radius: $border-radius;
  }

  .icon-circle {
    margin-right: 0;
  }
}

.text-truncate-depend {
  min-width: 0;
  padding-right: 50px;
}

.add-org-option-hover {
  overflow: hidden;

  .add-org-option {
    transition: 0.2s ease-in-out;
    transform: translateX(-125%);
    background: #fff;
    z-index: 1;
  }

  &:hover {
    .add-org-option {
      transform: translateX(0);
    }
  }
}
