.status {
  border-radius: 15px;
  padding: 2px 10px;
  background-color: rgba($text-light, 0.1);
  border: 1px solid rgba($text-light, 0.1);
  color: $text-light;
  font-size: 10px;
  font-weight: $fw-bold;

  &-active {
    background-color: rgba($primary, 0.1);
    border-color: rgba($primary, 0.1);
    color: $primary;
  }

  &-danger {
    background-color: rgba($red-1, 0.1);
    border-color: rgba($red-1, 0.1);
    color: $red-1;
  }

  &-warning {
    background-color: rgba($orange-1, 0.1);
    border-color: rgba($orange-1, 0.1);
    color: $red-1;
  }

  &-outline {
    border: 1px solid $text-light;
    background-color: $white;

    &.status {
      &-active {
        border-color: $primary;
        color: $primary;
      }
    }
  }
}

.status-high {
  border-color: $orange-1 !important;
  background: $orange-1 !important;
  color: #fff !important;
  min-width: 55px;
  text-align: center;
}

.status-none {
  border-color: $primary !important;
  background: $primary !important;
  color: #fff !important;
  min-width: 55px;
  text-align: center;
}

.status-medium {
  // border-color:  $gray-2!important;
  background: $gray-2 !important;
  color: $text-dark !important;
  min-width: 55px;
  text-align: center;
}