.empty-list {
  padding: 80px;
  background-color: $white;
  text-align: center;
  border-radius: $border-radius;
  mat-icon {
    width: 125px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 75px;
    color: #ddd;
    margin: 0 auto;
  }
}
