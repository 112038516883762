.followUps {
  &-thumb {
    position: relative;
  }

  &-like {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    text-align: center;
    line-height: 40px;
    background: rgba(255, 255, 255, 0.25);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    position: absolute;
    top: 1rem;
    right: 1rem;
    cursor: pointer;

    &:hover {
      background-color: #fff;

      .mat-icon {
        color: $orange-1;
      }
    }

    .mat-icon {
      line-height: 28px;
    }
  }

  &-contain {
    width: 100%;
    overflow: hidden;
    aspect-ratio: 5/2.5;
    border-radius: 8px;
    position: relative;

    &,
    p {
      color: #fff;
    }

    img {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      object-fit: cover;
      z-index: -1;
    }

    &:before {
      transition: 0.2s ease-in-out;
      content: '';
      width: 100%;
      height: 100%;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0) 100%);
      position: absolute;
      top: 0;
      left: 0;
      pointer-events: none;
      filter: contrast(0.05);
    }

    &:hover {
      p {
        height: auto;
        max-height: 100px;
        transition: max-height 0.25s ease-in;

      }

      &:before {
        filter: contrast(1)
      }
    }

    &-detail {
      padding: 1rem;
      position: absolute;
      bottom: 0;

      p {
        transition: 0.5s ease-in-out;
        max-height: 0;
        overflow: hidden;
        color: #fff !important;
        transition: max-height 0.15s ease-out;
        margin: 1rem 0 0;
        font-weight: normal;
      }


    }

  }

  &-type {
    font-size: 18px;
    position: relative;
    padding-bottom: 10px;
    font-weight: $fw-bold;

    &:after {
      content: '';
      width: 30%;
      height: 3px;
      background-color: $primary;
      border-radius: 2px;
      position: absolute;
      left: 0;
      bottom: 4px;
      display: inline-block;

    }
  }

  &-avatar {
    position: absolute;
    right: 1rem;
    bottom: -1.3rem;

    .avatar {
      border: 3px solid #fff;
      font-size: 16px;
    }
  }
}