wr-congrats-welcome {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 999;
  padding: 30px;
  overflow: auto;
  box-sizing: border-box;
  background: #fff;
  display: flex;
  .congratulations-panel {
    margin: auto;
  }
  p {
    line-height: 1.8 !important;
  }
}
.congratulations-panel {
  wr-onboarding-stepper {
    margin-bottom: 15px;
  }
  &.p-5 {
    @include media-breakpoint-down(sm) {
      padding: 1rem !important;
    }
  }
}
