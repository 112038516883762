mat-paginator {
  background: #aaa;
  [mat-icon-button] + [mat-icon-button] {
    margin-left: 10px;
  }
  mat-form-field {
    min-width: 80px;
    &.mat-form-field {
      .mat-form-field-wrapper {
        padding-bottom: 0;
        .mat-form-field-subscript-wrapper {
          margin-bottom: 0;
          display: none;
        }
      }
      .mat-form-field-flex {
        height: 40px;
        padding: 0 1rem !important;
      }
    }
  }
  .mat-paginator-range-label {
    margin: 0 15px 0 10px;
  }
  &.bg-transparent {
    .mat-form-field .mat-form-field-flex,
    .mat-form-field .mat-form-field-flex .mat-form-field-infix {
      background: $white !important;
    }
    input,
    textarea,
    select {
      &:-webkit-autofill {
        &,
        &:hover,
        &:focus,
        &:first-line {
          -webkit-box-shadow: 0 0 0 30px $white inset !important;
        }
      }
    }
  }
  .mat-paginator-page-size-select {
    margin-top: 2px;
    padding-bottom: 0;
  }
  .mat-icon-button {
    &.mat-button-base {
      background-color: #fff !important;
      &:not(.mat-button-disabled):hover {
        background-color: $primary !important;
        .mat-button-wrapper {
          color: #fff !important;
        }
      }
    }
    &.mat-button-disabled .mat-button-wrapper {
      color: $gray-3 !important;
    }
  }
}
.mat-form-field-wrapper {
  padding-bottom: 0;
}
