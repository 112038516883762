.card-details__label {
  min-width: 100px;
  font-weight: 500;
}

.card-details__value {
  flex-grow: 8;
}

.card-details__value__container {
  display: flex;
  //justify-content: space-between;
  width: 100%;
  font-size: 14px !important;

  &:not(:last-child) {
    margin-bottom: 8px;
  }


  // padding-left: 10px;

  .card-details {
    &__value {
      &__label {
        color: $text-dark;
        font-weight: $fw-regular;

        &.is-active {
          color: $green-1;
        }
      }
    }

    &__no-value__text,
    &__value__text {
      font-size: 14px;
      color: $text-light;
      overflow-wrap: normal;
    }
  }

}

.card-details {
  &__title {
    border-bottom: 1px solid $gray-1;
    font-size: 14px !important;
    margin-bottom: 8px;
    padding-bottom: 2px;
  }

  &__subtitle {
    font-size: 14px !important;
    margin-bottom: 2px !important;
  }

}

wr-document-summary-card-details-date-range~wr-document-summary-card-details-date-range {
  .card-details {
    &__value__container {
      margin-bottom: 0;
    }
  }
}

wr-document-summary-card-details-default~wr-document-summary-card-details-default,

wr-document-summary-card-details-date-range~wr-document-summary-card-details-date-range {
  margin-top: 0.75rem;
  display: block;
}

wr-document-summary-card-details-list~wr-document-summary-card-details-list {
  margin-top: 1.5rem;
  display: block;
}

wr-document-summary-card-details-priority-selector tr {
  @include respond(phone) {
    &:not(:last-child) td {
      border-bottom: 1px solid $gray-1;
    }

    td {
      padding: 10px 0;
    }
  }
}