@import '../responsive';

$wr-dialog--auto-size: (
  width: 85vw,
  min-width: 400px,
  max-width: 1700px,
  height: 95vh,
  min-height: 500px,
  max-height: 95vh,
);

// .mat-dialog-container {
//   max-width: 100vw !important;
//   width: 100%;
//   margin-top: 5%;
//   margin-bottom: 5%;
// }

.wr-dialog--auto-size {
  width: map-get($wr-dialog--auto-size, width);
  min-width: map-get($wr-dialog--auto-size, min-width);
  max-width: map-get($wr-dialog--auto-size, max-width) !important;
  height: map-get($wr-dialog--auto-size, height);
  min-height: map-get($wr-dialog--auto-size, min-height);
  max-height: map-get($wr-dialog--auto-size, max-height);

  mat-dialog-content,
  mat-dialog-container,
  mat-dialog-actions {
    padding: 0;
    margin: 0;
  }

  mat-dialog-container {
    overflow: hidden;
  }
}

.wr-summary-details {
  max-height: map-get($wr-dialog--auto-size, height);
  max-width: 320px;
}

.wr-dialog-form {
  max-height: map-get($wr-dialog--auto-size, height) !important;
  max-width: 560px !important;
}

.mat-dialog-container {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  border-radius: 6px !important;
  padding: 0 !important;
}

wr-generic-dialog {
  position: relative;

  @at-root .wr-dialog-small {
    @include respond(phone) {
      max-width: 90vw !important;
    }

    & {
      max-width: 500px;

      @include respond(phone) {
        max-width: 90vw !important;
      }
    }
  }

  @at-root .wr-dialog-hide-stepper & {
    .mat-horizontal-stepper-header-container {
      display: none !important;
    }

    .mat-stepper-horizontal .mat-horizontal-content-container {
      padding: 0 16px 16px !important;
    }
  }

  @at-root .wr-dialog-auto {
    max-height: map-get($wr-dialog--auto-size, height);
    min-width: 450px;
  }

  @at-root .wr-dialog-auto & {
    max-height: map-get($wr-dialog--auto-size, height);
  }

  @at-root .wr-dialog-full {
    height: 100%;
    width: 100%;
    max-height: 100dvh !important;
    max-height: 100vh !important;
    max-width: 100% !important;
  }

  @at-root .wr-dialog {
    height: map-get($wr-dialog--auto-size, height);
    width: 85vw;
    max-height: map-get($wr-dialog--auto-size, height) !important;
    max-width: 85vw !important;
  }

  @include respond(phone) {
    .horizontal-button-radio__form__radio-group,
    .horizontal-button-radio__form {
      height: auto !important;
    }
  }

  // .mat-dialog-content {
  //   padding: 0 !important;
  // }
  .wa-account-card {
    box-shadow: none;
    max-width: initial;
    padding: 0;
  }

  .mat-horizontal-stepper-header-container {
    position: absolute;
    bottom: 0;
    left: 1.5rem;
    height: 95px;
    padding: 0 !important;

    .mat-step-header.mat-horizontal-stepper-header {
      margin: 0 5px !important;
      height: 14px !important;
      width: 14px !important;

      @include respond(phone) {
        height: 8px !important;
        width: 8px !important;
        margin: 0 3px !important;
      }

      .mat-step-icon {
        height: 14px !important;
        width: 14px !important;
        padding: 0 !important;

        @include respond(phone) {
          height: 8px !important;
          width: 8px !important;
        }
      }
    }
  }
}

.wr-dialog {
  .mat-dialog-container {
    margin: 0;
  }

  &-max-width-900 {
    max-width: 900px !important;
    max-height: map-get($wr-dialog--auto-size, height) !important;

    .mat-tab-body-content {
      padding: 20px 0 0;
    }

    .heading__subTitle {
      max-width: 90% !important;
      margin-bottom: 1rem !important;
    }
  }

  &-full {
    .mat-dialog-container {
      margin: 0;
      border-radius: 0 !important;
    }
  }

  &-small {
    // max-width: ;
    .mat-dialog-container {
      max-width: 500px;
      width: 100%;
      max-height: map-get($wr-dialog--auto-size, height);

      @include respond(phone) {
        max-width: 90vw;
      }
    }
  }
}

.cdk-overlay-backdrop {
  background: rgba(0, 0, 0, 0.25);
}

mat-dialog-actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 0 !important;
}

@media (max-width: 768px) {
  .mat-dialog-container {
    border-radius: 0 !important;
  }

  .wr-dialog--auto-size {
    margin-top: 170px;
    width: 100%;
    min-width: 100%;
    max-width: 100% !important;
    height: 100%;
    min-height: calc(100vh - 82px) !important;
    max-height: 100%;
  }
}

.modal-class {
  @include respond(phone) {
    width: 100%;
  }
}

.wr-dialog {
  &-transparent {
    overflow: auto;

    .mat-dialog-container {
      background: transparent !important;
      box-shadow: none !important;
    }

    .mat-horizontal-stepper-header-container {
      display: none !important;
    }
  }

  &-stepper-hide {
    .mat-horizontal-stepper-header-container {
      display: none !important;
    }

    wr-access-card-settings-dialog,
    mat-horizontal-stepper,
    .mat-horizontal-content-container {
      height: 100%;
      display: block;
    }

    .mat-horizontal-stepper-content {
      display: flex;
      flex-flow: column;
      height: 100%;
      box-sizing: border-box;
      padding: 1rem;
    }

    form {
      height: auto;
      margin: auto;
    }
  }
}

.dialog {
  &-content {
    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 15px;
    }

    &-body {
      margin: 30px 0;
    }
  }
}

.max-vw-100 {
  max-width: 100vw !important;
}

.wr-dialog-body-padding-0 {
  mat-dialog-content,
  .mat-horizontal-stepper-content {
    padding: 10px 0 !important;

    @include respond(phone) {
      overflow: auto;
      padding: 10px 0 !important;
    }
  }
}

.wr-dialog-content-top-n32 {
  .mat-dialog-content {
    margin-top: -32px !important;
  }
}

wr-document-selector {
  width: 500px;
  display: block;

  @include respond(phone) {
    width: 100%;
  }
}

.wr-dialog-show-stepper-onheader {
  .mat-horizontal-stepper-header-container {
    position: initial;
    height: auto !important;

    .mat-step-header.mat-horizontal-stepper-header {
      width: auto !important;
      height: 24px !important;
      margin: auto !important;

      .mat-step-icon {
        height: auto !important;
        width: auto !important;
        padding: 3px !important;
      }
    }
  }
}

.mat-form-field.width-auto {
  width: auto !important;
  min-width: 150px;
}

.wa-stepper-inner-scroll {
  .wa-stepper-inner {
    height: 100%;
  }

  .modal-body-inner {
    overflow: auto;
    padding: 0 1rem;
    flex: 1 0 0%;
  }

  .mat-horizontal-content-container {
    height: calc(100% - 47px);
    // overflow: auto;
  }

  .mat-horizontal-stepper-content {
    display: flex;
    flex-flow: column;
    height: 100%;

    @include respond(phone) {
      overflow: auto;
      padding: 10px !important;
    }
  }

  &-footer {
    background: $white;
    text-align: right;
    padding-top: 1rem;
    margin-top: auto;
  }
}

.wr-dialog-bg-light {
  //@at-root .modal-bg-light, .mat-dialog-content {
  .mat-dialog-content {
    background-color: #f7fafd;
  }

  @at-root .modal-bg-light .mat-expansion-panel,
    .mat-expansion-panel {
    background-color: transparent;
  }
}

.wr-summary-bg-light {
  background-color: #f7fafd;
  margin: -1rem;
  height: calc(100% + 2rem) !important;

  wr-document-summary {
  }

  .mat-expansion-panel {
    background-color: transparent;
  }
}

.wr-summary-bg-transparent {
  background-color: transparent;
}

.wr-dialog-slide {
  // max-width: calc(100vw - 140px) !important;
  .mat-dialog-container {
    background-color: transparent !important;
    padding-top: 60px !important;
    display: flex;
    overflow: visible;
  }

  .mat-dialog-content {
    display: flex;
    align-items: center;
  }

  .header-top {
    background: #404040;
    align-items: center;
    border-bottom: 1px solid #555;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    box-sizing: border-box;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);

    p,
    .heading-close .mat-icon {
      color: #fff !important;
    }
  }
}

// .slide {
//   &-left,
//   &-right {
//     position: fixed;
//     top: 0;
//     height: 99vh;
//     width: 60px;
//     min-width: 10vw;
//     display: flex;
//     align-items: center;
//     justify-self: center;
//     cursor: pointer;
//     .mat-icon {
//       width: 40px;
//       height: 40px;
//       font-size: 30px;
//       display: flex;
//       align-items: center;
//       justify-content: center;
//       background: $white;
//       border-radius: 50%;
//       margin: auto;
//       &:hover {
//         background-color: $primary;
//         color: #fff;
//       }
//     }
//   }

//   &-left {
//     left: 0;
//   }
//   &-right {
//     right: 0;
//   }
// }

.cdk-overlay-backdrop.dark-backdrop {
  background: rgba(0, 0, 0, 0.75);
}

wr-generic-stepper-dialog .mat-horizontal-stepper-content[aria-expanded='true'],
wr-remove-user .mat-horizontal-stepper-content[aria-expanded='true'],
wr-select-leader .mat-horizontal-stepper-content[aria-expanded='true'] {
  min-height: 100%;
  display: flex;

  //align-items: center;
  form {
    // padding: 1rem;
    margin: auto;
    width: 100%;
    min-width: 100%;

    @media (max-width: $breakpoint--mobile) {
      margin: 0;
    }

    &.max-w-75 {
      max-width: 75%;
      min-width: 75%;

      @include respond(phone) {
        max-width: 100%;
        min-width: 100%;
      }
    }
  }

  wr-document-summary {
    min-height: 100%;
    width: 100%;
  }
}

wr-follow-up-scheduler .mat-horizontal-stepper-content[aria-expanded='true'] {
  display: block !important;
}

@include respond(phone) {
  .cdk-global-overlay-wrapper {
    justify-content: flex-start;

    .cdk-overlay-pane {
      margin-top: 5px;
    }
  }

  .cdk-overlay-container {
    //height: 100vh;
    z-index: 9999;
  }
}

.wr-dialog-mobile-menu {
  min-height: calc(100vh - 62px) !important;
  min-width: 100% !important;
}

.bottom-mobile-menu {
  min-width: calc(100vw - 20px) !important;
  width: calc(100vw - 20px) !important;
  border-radius: 6px;
  margin: 10px;
}

.form.multi-button-select__form {
  padding: 0 1rem;
  box-sizing: border-box;
}

// By Saroj
.custom-dialog-content {
  margin: 0 1.5rem;
  max-height: 50dvh;
  height: 100%;
  overflow-y: auto;
  padding-top: 1rem;
}

//End By Saroj

.wr-stepper-dot-style {
  .mat-horizontal-stepper-header {
    padding: 0 !important;
  }

  .mat-form-field {
    width: 100%;
  }

  .mat-step-label,
  .mat-stepper-horizontal-line,
  .mat-step-icon-content {
    display: none !important;
  }

  .mat-step-icon {
    border-radius: 10px;
    background: #e4e4e4;

    &-selected {
      background: $primary !important;
    }
  }
}

wr-onboarding,
wr-onboarding-kickoff {
  mat-dialog-content {
    height: auto !important;
  }

  // .mat-stepper-horizontal .mat-horizontal-content-container .mat-horizontal-stepper-content {
  //   display: flex;
  //   justify-content: center;
  //   flex-flow: column;
  // }
}
