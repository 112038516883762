@import '../theme';

body {
  * {
    scrollbar-width: thin;
    scrollbar-color: $green-1 #ddd;
  }

  *::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  *::-webkit-scrollbar-track {
    background: #ddd;
  }

  *::-webkit-scrollbar-thumb {
    background-color: $green-1;
    border-radius: 3px;
  }

  .content-review-questionaire {
    &::-webkit-scrollbar-thumb {
      background-color: #ddd;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }
  }
}