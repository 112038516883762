.mat-card-list {
  &.mat-card {
    border: 1px solid $background-2;
    box-shadow: none !important;
  }

  .mat-card-header {
    background-color: $background-2;
    margin: -1rem -1rem 1rem !important;
    border-radius: 4px 4px 0 0;
    padding: 6px;

    .mat-card-title {
      margin-bottom: 0;
      padding: 0.5rem 1rem;
      color: #000;
    }
  }

  mat-icon {
    color: $icon-light;
    font-size: 20px;
    vertical-align: middle;
    margin-right: 5px;
  }
}

.scheduler-summary {
  font-weight: $fw-regular !important;

  mat-icon {
    font-size: 20px;
    margin-right: 6px;
    vertical-align: middle;
  }

  .mat-card {
    box-shadow: $shadow !important;
  }

  &-item {
    color: $text-light !important;

    p {
      color: $text-dark !important;
      margin-bottom: 0.5rem;
    }

    &+& {
      margin-top: 1.5rem;
    }
  }
}

.badge {
  padding: 4px 10px;
  border-radius: $border-radius;
  background-color: $background-1;
  color: $text-light;
  font-weight: $fw-regular;

  &+& {
    margin-left: 0.5rem;
  }
}

.schedule-radio-custom {
  .mat-radio-label {
    padding: 0 15px 0 15px;
    position: relative;
    min-width: 100px;
    border-radius: $border-radius;
    background-color: #eff4fa !important;
    line-height: 50px;
    height: 50px;
    display: inline-flex;
    align-items: center;
    transition: 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
    font-weight: $fw-regular;
    color: $text-light;
    box-sizing: border-box;
    justify-content: center;
  }

  .mat-radio-label-content {
    padding: 0;
  }

  .mat-radio-outer-circle,
  .mat-radio-inner-circle,
  .mat-radio-container {
    display: none;
  }

  &.mat-radio-checked {
    .mat-radio-label {
      background-color: $primary !important;
      color: $white !important;
    }
  }
}

.scheduler-message {
  &.mat-form-field .mat-form-field-flex {
    height: auto;
    min-height: 50px;
    padding: 0 !important;

    textarea {
      line-height: 22px !important;
    }

    .mat-form-field-label {
      padding: 4px 10px;
    }

    .mat-form-field-infix {
      padding: 10px !important;
    }
  }
}

.form-inline-label {
  line-height: 50px;
}

.mat-form-width-xs {
  max-width: 100px;
  min-width: auto !important;

  @include respond(phone) {
    max-width: 100%;
  }
}

.mat-expansion-panel {
  box-shadow: none !important;
}

.mat-expansion-panel-header {
  height: 40px !important;

  .mat-expansion-indicator {
    padding: 7px 7px 16px;
  }
}

.mat-expansion-panel-spacing {
  margin: 0 !important;
}

.upcoming-list {
  max-height: calc(100vh - 645px);
  min-height: 200px;
  overflow: auto;

  .mat-list-item-content {
    padding-left: 0 !important;
  }
}

.wa-dialog-scheduler {

  .wa-stepper-inner {
    padding: 1rem;
    box-sizing: border-box;
    border-radius: 6px;
  }

  .attendees-optional {
    max-width: 500px;
    margin: auto;
    width: 100%;
    // overflow: auto;
    padding: 30px;
  }

  .list-teamspace {
    max-height: auto;
  }

  &.wa-stepper-inner-scroll .wa-stepper-inner {
    // box-shadow: $shadow;
    border: 1px solid #eee;
  }

  .scheduler-summary {
    .mat-card {
      background: #f5f5f5;
      box-shadow: none !important;
    }
  }
}

// .wa-dialog-scheduler {
//   .mat-dialog-content {
//     padding: 3rem 3rem calc(82px + 3rem) !important;
//   }
//   .wa-stepper-inner {
//     padding: 1rem;
//     box-sizing: border-box;
//     border-radius: 6px;
//     &-scroll-footer {
//       position: absolute;
//       bottom: 0;
//       width: 100%;
//       left: 0;
//       padding: 1rem;
//       box-sizing: border-box;
//     }
//   }
// }

@include respond(phone) {

  wr-scheduler .mat-date-custom,
  .w-sm-100 {
    width: 100% !important;
    min-width: 100% !important;
  }

}