@import 'src/styles/theme';
@import 'src/styles/breakpoints';

.mat-checkbox {
  .mat-checkbox-frame {
    border-color: transparent;
    background-color: $background-2;
  }

  .mat-checkbox-layout {
    max-width: 100%;

    .mat-checkbox-label {
      max-width: calc(100% - 24px);
      white-space: normal;

      a {
        font-size: 14px !important;
      }

      @media (max-width: $breakpoint--small-screen) {
        justify-content: left;
      }
    }
  }

  &.button-type {
    position: relative;

    &.big-button {

      &,
      .mat-checkbox-layout,
      .mat-checkbox-inner-container,
      .mat-checkbox-label {
        height: 50px !important;
        max-width: 370px !important;
        width: 100% !important;

        @at-root .multi-button-select-custom-big & {
          height: 200px !important;
          max-width: 250px !important;
          display: flex;
          flex-flow: column wrap;
          padding: 10px;
        }

        @media (max-width: $breakpoint--mobile) {
          max-width: 250px !important;
        }
      }

      .mat-checkbox-label {
        font-size: 16px;
      }
    }

    .mat-checkbox-background {
      background-color: $gray-1;
      border-radius: 8px;
    }

    &.mat-checkbox-checked {
      .mat-checkbox-background {
        background-color: $green-1;
      }

      .mat-checkbox-label {
        color: $text-white !important;
      }
    }

    &.mat-checkbox-disabled {
      .mat-checkbox-background {
        background-color: $gray-3;
      }
    }

    .mat-ripple {
      display: none;
    }

    .mat-checkbox-label {
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 700;
      font-size: 22px !important;
      line-height: 27px !important;
      color: $text-light !important;
    }

    &,
    .mat-checkbox-layout,
    .mat-checkbox-inner-container {
      width: 100%;
      height: 24px;
    }

    .mat-checkbox-background {
      svg {
        display: none;
      }
    }
  }
}

.multi-button-select-custom-big {
  gap: 30px;
  flex-wrap: wrap;
  justify-content: center;

  @include ratio-125 {
    gap: 20px;
  }
}

@include respond(phone) {
  .mat-radio-button {
    ~.mat-radio-button {
      margin-left: 0;
    }

    +.mat-radio-button {
      margin-top: 0.5rem;
    }
  }
}

.mat-checkbox-layout .mat-checkbox-inner-container {
  margin-top: 5px;
}