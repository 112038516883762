.user-wa-status-block {
  min-height: 110px;

  @media screen and (max-width: 991px) {
    min-height: 275px;
  }

  .mat-icon.fs-1 {
    font-size: 75px !important;
    display: block;
    color: rgba($text-light, 0.25);
    font-weight: 400;

    @media screen and (max-width: 991px) {
      height: 150px !important;
      display: flex;
      align-items: center;
    }
  }

  .fs-1 {
    font-size: 50px !important;
    line-height: 60px;
    font-weight: 600;
  }

  @media screen and (max-width: 991px) {
    .d-flex {
      min-width: 100%;
      align-items: center;
      border-top: 1px solid $border-color-1;
      padding-top: 1.5rem;
    }
  }
}

.home-title {
  font-size: 14px;
  text-align: left;
  position: relative;
  text-transform: uppercase;
  font-weight: $fw-bold;
  letter-spacing: 0.5px;
  color: $text-muted;
  padding: 5px 0 5px 16px;

  @include ratio-125 {
    font-size: calc(#{$fs-base} - #{$ratio-125-value});
    padding-left: calc(#{$fs-base} - #{$ratio-125-value});
  }

  &,
  &.mat-card-title {
    margin-bottom: 1.5rem;
  }

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 4px;
    height: 100%;
    border-radius: 4px;
    background: $primary;
  }
}

.home {
  &-accordion {
    .mat-expansion {
      &-panel {
        background: transparent;

        &-header {
          padding: 0;

          @include ratio-125 {
            font-size: 12px;
          }

          &:hover {
            background: transparent !important;
          }
        }

        &-header-title {
          justify-content: flex-end;
          color: $primary;
          font-weight: $fw-bold;
        }

        &-body {
          padding-left: 0;
          padding-right: 0;
        }
      }

      &-indicator {
        border: 2px solid $primary;
        border-radius: 50%;
        width: $base-size;
        height: $base-size;
        display: block;
        text-align: center;
        line-height: 10px;
        padding: 7px !important;

        @include ratio-125 {
          width: calc(#{$base-size} - #{$ratio-125-value});
          height: calc(#{$base-size} - #{$ratio-125-value});
          line-height: 7px;
        }

        &:after {
          color: $primary;
        }
      }
    }
  }

  &-info-accordion {
    .mat-expansion-panel-header.mat-expanded {
      .mat-expansion-indicator {
        // margin-bottom: -35px;
        position: relative;
        z-index: 9;
        opacity: 0;
        //background: rgb(255, 255, 255);
      }
    }
  }
}

.icon-show {
  opacity: 1 !important;
}

.icon-animate {
  animation-name: heartBeat;
  animation-duration: 1.3s;
  animation-timing-function: ease-in-out;
}

// .info-icon {
//   opacity: 0;
// }

// __<<ngThemingMigrationEscapedComment0>>__
// @keyframes heartBeat {
//   0% {
//     transform: scale(1);
//   }

//   14% {
//     transform: scale(1.3);
//   }

//   28% {
//     transform: scale(1);
//   }

//   42% {
//     transform: scale(1.3);
//   }

//   70% {
//     transform: scale(1);
//   }
// }

// .heartBeat {
//   animation-name: heartBeat;
//   animation-duration: calc(var(--animate-duration) * 1.3);
//   animation-timing-function: ease-in-out;
// }
// __<<ngThemingMigrationEscapedComment1>>__

.home-two-card {
  mat-card {
    min-height: 245px;
    height: 100%;
    box-sizing: border-box;
  }

  .mat-radio-group {
    gap: 10px;
    display: flex;
    justify-content: center;
    flex-flow: row wrap;
  }

  .col-md-6 {
    &:first-child {
      padding-right: 3rem;
    }

    &:last-child {
      padding-left: 3rem;
    }
  }
}

.button-animate {
  opacity: 0;
  display: none;

  button,
  .icon-animated {
    position: absolute;
    top: 120%;
    left: 0;
    transition: 1s ease-in-out;
    opacity: 0;
    transform: scale(0);
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation--mode: both;
    animation-fill-mode: both;
  }

  &.active {
    opacity: 1;
    display: block;

    button,
    .icon-animated {
      -webkit-animation-name: tada;
      animation-name: tada;
      position: initial;
      opacity: 1;
      transform: scale(1);
    }
  }
}

#question-of-the-day {
  .mat-dialog-actions.footer {
    display: none;
  }
}

@-webkit-keyframes tada {
  0% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }

  10%,
  20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9) rotate(-3deg);
    transform: scale3d(0.9, 0.9, 0.9) rotate(-3deg);
  }

  30%,
  50%,
  70%,
  90% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate(3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate(3deg);
  }

  40%,
  60%,
  80% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate(-3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate(-3deg);
  }

  to {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
}

@keyframes tada {
  0% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }

  10%,
  20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9) rotate(-3deg);
    transform: scale3d(0.9, 0.9, 0.9) rotate(-3deg);
  }

  30%,
  50%,
  70%,
  90% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate(3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate(3deg);
  }

  40%,
  60%,
  80% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate(-3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate(-3deg);
  }

  to {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
}

.home-width,
.width-1200 {
  max-width: 1200px;
  margin: 0 auto;
}

.width-1500 {
  max-width: 1500px;
  margin: 0 auto;
}

.workplace-action-submitted {
  min-height: 180px;
  display: flex;
  align-items: center;
  justify-self: center;
  padding: 30px;
  box-sizing: border-box;
  flex-direction: column;
  gap: 10px;
}

.row-rounded-left {
  border-radius: 48px 0 0 48px;
  min-height: 48px;
  background: $white;
  line-height: 48px;
  padding: 0 16px;
  display: flex;
  justify-content: space-between;
}

.card-activity {
  font-size: 1rem;
  background-color: rgba($white, 1);
  height: 100%;
  min-height: 220px;
  box-sizing: border-box;
  transform: translate(0, 0);
  transition: 0.2s ease-in-out;
  cursor: pointer;

  &:hover {
    transform: translate(0, -2.5px);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.07);

    .card-activity-detail:after {
      transform: rotate(45deg) scale(1.5);
    }
  }

  .wr-summary-bg-light {
    background: transparent;
  }

  .mat-horizontal-stepper-header-container {
    padding: 1rem !important;
    justify-content: flex-start !important;

    .mat-step-header.mat-horizontal-stepper-header,
    .mat-step-header.mat-horizontal-stepper-header .mat-step-icon {
      width: 5px !important;
      height: 5px !important;
    }

    .mat-step-header.mat-horizontal-stepper-header {
      margin: 0 3px !important;
    }

    .mat-step-header.mat-horizontal-stepper-header .mat-step-icon {
      background-color: $text-lighter !important;
    }
  }

  .mat-stepper-horizontal .mat-horizontal-content-container {
    height: auto !important;
  }

  .badge {
    display: inline-block;
    border-radius: 15px;
    font-size: 10px;
    padding: 0 10px;
    margin-bottom: 5px;
    background-color: $text-light;
    color: #fff;
  }

  &-title {
    font-weight: 500;
    line-height: 1.5;
    font-size: 1rem;
    min-height: 40px;
    // display: flex;
    // align-items: flex-start;
    // flex-flow: column;
  }

  &-link {
    position: relative;

    .card-activity-detail {
      position: relative;
      padding-right: 1.8rem;

      &:after {
        content: '';
        position: absolute;
        right: 0.5rem;
        bottom: 0.75rem;
        width: 0.75rem;
        height: 0.75rem;
        border-top: 4px solid;
        border-right: 4px solid;
        border-color: $text-light;
        transition: 0.2s ease-in-out;
        transform: rotate(45deg);
        opacity: 0.5;
        cursor: pointer;
      }
    }
  }

  &-primary {
    background-color: rgba($primary, 0.15);

    .mat-horizontal-stepper-header-container .mat-step-header.mat-horizontal-stepper-header .mat-step-icon-selected {
      background-color: $primary !important;
    }

    .card-activity-stepper-button .mat-icon-button .active .mat-icon,
    .material-icons.fs-3 {
      color: $primary;
    }

    .card-activity-detail:after {
      border-color: $primary;
    }
  }

  &-dark {
    background-color: rgba($text-light, 0.75);
    color: #fff;

    .mat-horizontal-stepper-header-container .mat-step-header.mat-horizontal-stepper-header .mat-step-icon-selected {
      background-color: $text-light !important;
    }

    .card-activity-stepper-button .active .mat-icon,
    .material-icons.fs-3 {
      color: $white;
    }

    .badge {
      background-color: $white;
      color: $text-light;
    }

    .card-activity-detail:after {
      border-color: $white;
    }
  }

  &-warning {
    background-color: rgba($orange-1, 0.15);

    .mat-horizontal-stepper-header-container .mat-step-header.mat-horizontal-stepper-header .mat-step-icon-selected {
      background-color: $orange-1 !important;
    }

    .card-activity-stepper-button .active .mat-icon,
    .material-icons.fs-3 {
      color: $orange-1;
    }

    .badge {
      background-color: $orange-1;
    }

    .card-activity-detail:after {
      border-color: $orange-1;
    }
  }

  &-danger {
    background-color: rgba($danger, 0.15);

    .mat-horizontal-stepper-header-container .mat-step-header.mat-horizontal-stepper-header .mat-step-icon-selected {
      background-color: $danger !important;
    }

    .card-activity-stepper-button.active .mat-icon,
    .material-icons.fs-3 {
      color: $danger;
    }

    .badge {
      background-color: $danger;
    }

    .card-activity-detail:after {
      border-color: $danger;
    }
  }
}

.card-activity-stepper-button {
  position: absolute;
  right: 0.5rem;
  bottom: 0.5rem;

  .mat-icon {
    font-size: 20px;
    opacity: 0.5;
  }

  .active .mat-icon {
    opacity: 1;
  }
}

.mde-popover-content .mat-card {
  width: 200px !important;
  padding: 0.5rem !important;
}

.mde-popover-panel p {
  font-size: 12px !important;
}

.activity-slide {
  transition: all 5s ease-in-out;

  &-action {
    .mat-icon-button {
      .mat-icon {
        font-size: 30px !important;
        opacity: 0.5;
        font-weight: 300;
      }

      &.active .mat-icon {
        opacity: 1;
        color: $primary;
      }
    }
  }

  >.mat-stepper-horizontal {
    >.mat-horizontal-stepper-wrapper {
      >.mat-horizontal-stepper-header-container {
        display: none !important;
      }
    }

    >.mat-horizontal-content-container {
      padding: 0 !important;
    }
  }
}

.home-tooltip {
  * {
    font-size: 12px !important;
  }

  :focus-visible {
    outline: none;
  }

  &-img img {
    height: 150px !important;
    object-fit: cover;
    margin-bottom: 5px;
    width: 100%;
  }
}