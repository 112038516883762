.follow-up-option {
  text-align: center;
  font-weight: 500;
  transition: 0.2s ease-in-out;
  cursor: pointer;

  &.mat-card {
    border-radius: 1rem !important;
  }

  &.active {
    background: $primary;
    color: #fff;

    p {
      color: $white !important;
    }

    mat-icon {
      background: $white;
      color: $primary-dark;
      box-shadow: 0 0 15px rgba(0, 0, 0, 0.16);
    }

    &.info {
      background-color: $blue;

      mat-icon {
        background: $white;
        color: $blue;
      }
    }

    &.warning {
      background-color: $orange-1;

      mat-icon {
        background: $white;
        color: $orange-1;
      }
    }
  }

  p {
    font-size: 18px !important;
    padding: 0 0.75rem;
  }

  mat-icon {
    color: $green-1;
    background: rgba($green-1, 0.12);
    width: 80px;
    height: 80px;
    border-radius: 50%;
    font-size: 40px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0.5rem;
  }

  &.info {
    mat-icon {
      color: $blue;
      background: rgba($blue, 0.12);
    }
  }

  &.warning {
    mat-icon {
      color: $orange-1;
      background: rgba($orange-1, 0.12);
    }
  }
}

.boxSizing {
  box-sizing: border-box;
}