$col-padding: 45px;
$after: calc(#{$col-padding} - 33px);
$addmorespace: calc(#{$col-padding} - 15px);
$tree-hor-linewidth: $after;

.mat-table-teams {
  &.mat-table-custom {
    border-spacing: 0 5px !important;
  }

  &.mat-table-custom-list tr.mat-row {
    @include media-breakpoint-down(sm) {
      padding: 0;
      // background-color: #fff !important;
      box-shadow: 6px 6px 6px rgb(0 0 0 / 6%);
      border-radius: 6px;
      margin-bottom: 15px;
    }
  }

  .drop-icon {
    width: 20px;
    height: 40px;
    margin-left: -5px;
  }

  .example-element-detail {
    position: relative;

    td:first-child {
      padding-left: 10px;
    }

    padding-left: 0px;
  }

  @include media-breakpoint-down(sm) {
    .teams-workspace-container {
      padding: 1rem 0 0;
    }
  }

  @include media-breakpoint-up(sm) {

    .example-element-row .example-element-row,
    .inner-table .mat-row:not(.example-detail-row) {
      td:first-child {
        .d-flex {
          position: relative;

          &:after {
            content: '';
            width: $tree-hor-linewidth;
            height: 1px;
            background: #c4c4c4;
            position: absolute;
            top: 50%;
            left: calc(-#{$col-padding} + 25px);
          }

          &:before {
            content: '';
            width: 1px;
            height: 15000px;
            background: #c4c4c4;
            position: absolute;
            bottom: 50%;
            left: calc(-#{$col-padding} + 25px);
          }
        }
      }
    }

    .mat-column {
      &-name {
        min-width: 250px;
        max-width: 100%;
        width: 100%;
        box-sizing: border-box;
      }

      &-jobTitle {
        max-width: 300px;
        min-width: 300px;
        width: 300px;
        box-sizing: border-box;
      }

      &-hrContactName {
        max-width: 200px;
        min-width: 150px;
        width: 200px;
        box-sizing: border-box;
      }

      &-email {
        max-width: 400px;
        min-width: 300px;
        width: 400px;
        box-sizing: border-box;
      }

      &-status {
        width: 115px;
        min-width: 115px;
        max-width: 115px;
        box-sizing: border-box;
      }
    }
  }

  &.mat-table-white tr.mat-row:not(.example-detail-row) {
    &:after {
      box-shadow: none;
      background-color: transparent !important;
    }

    td {
      @include media-breakpoint-up(sm) {
        &:after {
          // content: '';
          // width: 100%;
          // height: calc(100% - 15px);
          // background-color: #eff4fa;
          // position: absolute;
          // left: 0;
          // top: 0;
          // background-color: #fff !important;
          // box-shadow: 6px 6px 6px rgb(0 0 0 / 6%);
          // z-index: -1;
        }

        &:first-child:after {
          border-radius: 50px 0 0 50px;
          padding-left: 0;
        }

        &:last-child {
          border-radius: 0 6px 6px 0;
        }
      }
    }
  }

  @include media-breakpoint-up(sm) {
    .inner-table {
      position: relative;
      overflow: hidden;
      box-sizing: border-box;

      .teams-workspace-title-container {
        padding-left: 5px;
      }

      padding-left: $col-padding !important;

      &:after {
        left: $after !important;
      }

      // .inner-table {
      //   padding-left: calc((#{$col-padding}) * 1.5) !important;

      //   &:after {
      //     left: calc(#{$after} * 2) !important;
      //   }

      //   .inner-table {
      //     padding-left: calc((#{$col-padding}) * 2) !important;

      //     &:after {
      //       left: calc(#{$after} * 3) !important;
      //     }

      //     .inner-table {
      //       padding-left: calc((#{$col-padding}) * 2.5);

      //       &:after {
      //         left: calc(#{$after} * 4) !important;
      //       }

      //       .inner-table {
      //         padding-left: calc((#{$col-padding} + 3px) * 3);

      //         &:after {
      //           left: calc(#{$after} * 5) !important;
      //         }

      //         .inner-table {
      //           padding-left: calc((#{$col-padding} + 3.5px) * 3.5);

      //           &:after {
      //             left: calc(#{$after} * 6) !important;
      //           }

      //           .inner-table {
      //             padding-left: calc((#{$col-padding} + 4px) * 4);

      //             &:after {
      //               left: calc(#{$after} * 7) !important;
      //             }

      //             .inner-table {
      //               padding-left: calc((#{$col-padding} + 4.5px) * 4.5);

      //               &:after {
      //                 left: calc(#{$after} * 8) !important;
      //               }

      //               .inner-table {
      //                 padding-left: calc((#{$col-padding} + 5px) * 5);

      //                 &:after {
      //                   left: calc(#{$after} * 9) !important;
      //                 }

      //                 .inner-table {
      //                   padding-left: calc((#{$col-padding} + 5.5px) * 5.5);

      //                   &:after {
      //                     left: calc(#{$after} * 10) !important;
      //                   }
      //                 }
      //               }
      //             }
      //           }
      //         }
      //       }
      //     }
      //   }
      // }
    }
  }
}

.teams-workspace {
  &-container {
    min-height: 20px;
    padding: 10px 10px 0 10px;
    position: relative;
    margin-left: -5px;

    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 3px;
      //background: $primary;
      bottom: -7px;
      left: 0;
      border-radius: 4px 4px 0 0;
      right: 0;
    }
  }

  &-title {
    font-size: 12px !important;
    text-transform: uppercase;
    font-weight: 600 !important;
    position: relative;

    @include media-breakpoint-up(sm) {
      &:after {
        // content: '';
        // height: 7px;
        // width: 7px;
        // border-radius: 50%;
        // position: absolute;
        // left: -15px;
        // top: calc(50% - 4px);
        // background-color: #c4c4c4;
        // z-index: 3;
      }

      &:before {
        // content: '';
        // width: 3px;
        // height: 200px;
        // bottom: 50%;
        // background-color: $bg-light;
        // position: absolute;
        // left: -13px;
        // z-index: 2;
      }
    }
  }
}

.teams-add-workspace {
  background-color: #fff;
  border-radius: 50%;
  cursor: pointer;
  margin-left: 0.75rem;

  .mat-icon {
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    font-size: 14px;
    color: $dark;
    display: block;
  }
}

.team {

  &-name,
  &-status,
  &-jobtitle,
  &-email,
  &-hrcontact {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &-name {
    display: inline-block;
  }

  &-status {
    width: 100px;
  }
}

.mat-column-name {
  .avatar {
    min-width: $avatar-size;
    margin-right: 8px;
  }
}