@import '../theme';
.slider {
  &-wrapper {
    position: fixed;
    top: 56px;
    right: 0;
    background-color: rgba(0, 0, 0, 0.16);
    width: 100%;
    height: calc(100vh - 85px);
    z-index: 1;
  }
  &-right {
    position: absolute;
    right: 0;
    top: 0;
    width: 350px;
    background-color: #fff;
    padding: 1.5rem;
    height: calc(100vh - 85px);
  }
}
.content-type {
  font-weight: $fw-bold;
  font-size: small;
  cursor: pointer;
  box-shadow: $shadow;
  transition: 0.25s ease-in-out;
  &:hover {
    color: $primary;
    box-shadow: $shadow-lg;
  }
  .mat-icon {
    width: auto !important;
    height: auto !important;
    line-height: 1 !important;
    color: $primary;
  }
}

.mat-slider-horizontal .mat-slider-track-fill,
.mat-slider-horizontal .mat-slider-track-background,
.mat-slider-horizontal .mat-slider-track-wrapper,
.mat-slider-horizontal .mat-slider-wrapper,
.mat-slider-horizontal .mat-slider-track-wrapper {
  height: 6px !important;
  border-radius: 6px;
}
.mat-slider-thumb {
  bottom: -12px !important;
  width: 26px !important;
  height: 26px !important;
}
