@import "../theme";

// $green-1: #00c7b2;
// $green-2: #06e1ca;

.wrap {
    position: absolute;
    right: 0;
    top: 40%;
    width: 350px;
    left: 0;
    margin: 0 auto;
}

/* select starting stylings ------------------------------*/
.select {
    font-family:
        'Roboto', 'Helvetica', 'Arial', sans-serif;
    position: relative;
    width: 350px;
}

.select-text {
    position: relative;
    font-family: inherit;
    background-color: transparent;
    width: 350px;
    padding: 10px 10px 10px 10px;
    font-size: 18px;
    border-radius: 4px;
    border: 2px solid rgba(0, 0, 0, 0.12);
}

/* Remove focus */
.select-text:focus {
    outline: none;
    border: 2px solid $green-1;
}

/* Use custom arrow */
.select .select-text {
    appearance: none;
    -webkit-appearance: none;
    color: gray;
}

.select:after {
    position: absolute;
    top: 18px;
    right: 10px;
    /* Styling the down arrow */
    width: 0;
    height: 0;
    padding: 0;
    content: '';
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid rgba(0, 0, 0, 0.12);

    pointer-events: none;
}

/* LABEL ======================================= */
.select-label {
    color: rgba(0, 0, 0, 0.26);
    background: white;
    font-size: 18px;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    padding: 0 5px;
    left: 10px;
    top: 12px;
    transition: 0.2s ease all;
}


/* active state */
.select-text:focus~.select-label,
.select-text:valid~.select-label {
    color: $green-1;
    top: -8px;
    transition: 0.2s ease all;
    font-size: 14px;
}

.select-text:valid:not(focus)~.select-label {
    color: rgba(0, 0, 0, 0.26);
    background: red;
}

.select-text:valid:focus~.select-label {
    color: $green-1;
}