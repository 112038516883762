.mat-icon {

  text-align: center;
  line-height: 24px;

  .icon {
    &-svg {
      &-primary {
        width: 100% !important;
      }
    }
  }
}