.subscription-dialog-content {
  width: 100%;
  max-width: 800px;
}

.subscription-company-list {
  max-height: 355px;
  overflow: auto;
}

.mat-stroked-button.mat-button-default {
  border: 1px solid #999 !important;

  &:not(:hover):not(:active) .mat-button-wrapper {
    color: #999;
  }
}

.wa-subscription-update {
  .mat-card {
    height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-flow: column;

    &-actions {
      margin-top: auto !important;
    }
  }

  .border-light:hover {
    border: 1px solid $primary !important;
  }
}