@import 'src/styles/theme';
@import 'src/styles/breakpoints';

.table-responsive {
  width: 100%;
  overflow: auto;
}

.mat-table-custom {
  border-spacing: 0 16px !important;

  &-list {
    font-weight: $fw-regular;

    &,
    .inner-table {
      box-shadow: none !important;
      width: 100%;
    }

    .inner-table {
      margin-bottom: 0.75rem;

      tr:last-child .inner-table {
        margin-bottom: 0;
      }
    }

    &.mat-table-custom-list-header-show {
      &.mat-table .mat-table thead {
        display: table-header-group;
      }

      .example-element-detail {
        padding: 0 !important;
      }

      .rating-active,
      .rating {
        img {
          width: 24px;
        }
      }
    }

    // thead {
    //   color: $text-dark;
    //   font-weight: $fw-bold;
    // }
    .mat-cell {
      color: $text-light;
      white-space: nowrap;
      background: $white;

      @include respond(phone) {
        white-space: wrap;
      }

      padding-left: 1rem;
      padding-right: 1rem;
    }

    tr.mat-header-row {
      height: auto;

      th {
        padding: 0 15px;
      }

      .mat-header-cell {
        color: $text-light;
        font-weight: $fw-bold;
      }
    }

    .mat-column-expandedDetail {
      padding: 0 !important;
      width: 100% !important;
    }

    thead tr {
      height: auto !important;
    }

    @include media-breakpoint-down(sm) {
      thead {
        display: none !important;
      }

      tr {
        &.mat-row {
          height: auto !important;
          padding: 1rem !important;

          td {
            &:not(:first-child) {
              margin-top: 0.5rem;
            }

            &[data-col]:before {
              content: attr(data-col);
              min-width: 55px;
              display: block;
              font-weight: $fw-bold;
              font-size: 9px;
              text-transform: uppercase;
              color: #aaa;
              letter-spacing: 0.5px;
            }
          }

          &:not(.example-detail-row):after {
            border-radius: 6px;
          }

          &.example-detail-row {
            padding: 0 !important;
          }
        }

        &,
        td {
          display: block;
          padding: 0 !important;
        }

        td {
          &:first-of-type {
            border-radius: 6px;
          }

          &.row-action {
            position: absolute;
            right: 15px;
            top: 10px;
          }

          &.has-row-action {
            padding-right: 2rem;
          }
        }
      }

      tr.mat-row:not(.example-detail-row),
      tr.mat-footer-row {
        height: auto !important;
      }
    }

    th.mat-header-cell:first-of-type,
    td.mat-cell:first-of-type,
    td.mat-footer-cell:first-of-type {
      padding-left: 10px;
    }

    &-onboarding {
      td {
        background: $bg-light !important;
      }

      @include media-breakpoint-up(sm) {
        th,
        td:not(.mat-column-expandedDetail) {
          &:first-child {
            min-width: 400px;
          }

          &:nth-child(2) {
            min-width: 200px;
            width: 100%;
          }

          &:nth-child(3) {
            min-width: 100px;
          }

          &:nth-child(4) {
            min-width: 150px;
          }
        }

        .inner-table {
          th,
          td:not(.mat-column-expandedDetail) {
            &:first-child {
              min-width: 360px;
            }
          }
        }
      }
    }
  }

  // &.mat-table-custom-last-column-action {
  //   th.mat-header-cell:last-of-type,
  //   td.mat-cell:last-of-type,
  //   td.mat-footer-cell:last-of-type {
  //     width: 75px;
  //   }
  // }

  .mat-table thead {
    display: none;
  }
}

mat-row,
mat-header-row,
mat-footer-row,
th.mat-header-cell,
td.mat-cell,
td.mat-footer-cell {
  border-color: transparent;
}

tr.mat-row:not(.example-detail-row),
tr.mat-footer-row {
  border-radius: 50px 6px 6px 50px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.06);

  @include media-breakpoint-down(sm) {
    border-radius: 6px;
    background: #fff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.06);
    margin-bottom: 1rem;
  }
}

.example-detail-row {
  height: 0 !important;
  overflow: hidden !important;

  > td {
    background: transparent !important;
  }
}

.example-element-detail {
  opacity: 0;
}

.example-expanded-row + tr .example-element-detail {
  opacity: 1;
  height: auto !important;
  min-height: auto !important;
}

tr.mat-row {
  position: relative;

  // &:not(.example-detail-row):after {
  //   content: '';
  //   width: 100%;
  //   height: calc(100% - 15px);
  //   background-color: $bg-light;
  //   position: absolute;
  //   left: 0;
  //   top: 0;
  //   border-radius: 50px 6px 6px 50px;
  // }

  td {
    position: relative;
    z-index: 1;
    // padding-bottom: 15px;
    height: auto;
  }
}

.example-element-detail {
  padding-left: 40px;
}

.drop-icon {
  position: relative;
  float: left;
  width: 30px;
  height: 35px;
  cursor: pointer;

  &:hover:after {
    border-color: transparent transparent $primary transparent;
  }

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(180deg);
    width: 0;
    height: 0;
    border-width: 0 3px 5px 3px;
    border-color: transparent transparent $text-light transparent;
    border-style: solid;
    transition: 0.25s ease-in-out;

    @at-root .example-expanded-row & {
      transform: translate(-50%, -50%) rotate(0);
    }
  }
}

.mat-column-question {
  .d-flex {
    span:first-child.team-name {
      margin-left: 10px;
    }
  }
}
