.mat-table {
  border-spacing: 0 10px !important;

  &-white {
    tr.mat-row:not(.example-detail-row):after {
      // background-color: #fff !important;
      // box-shadow: 0 4px 6px rgba(0, 0, 0, 0.06);
    }
  }

  &-custom {

    th.mat-header-cell:first-of-type,
    td.mat-cell:first-of-type,
    td.mat-footer-cell:first-of-type {
      padding-left: 8px;
      border-radius: 50px 0 0 50px;
    }

    th.mat-header-cell:last-of-type,
    td.mat-cell:last-of-type,
    td.mat-footer-cell:last-of-type {
      border-radius: 0 6px 6px 0;
    }
  }
}

.mat-table-custom-company {
  .example-element-detail {
    overflow: hidden;
    display: inline-block;
  }
}


.mat-table-custom.mat-table-custom-leadership {
  &.mat-table-teams {
    .mat-column-name {
      width: auto;
      min-width: initial;
      max-width: initial;
    }

    .team-name {
      white-space: normal;
      overflow: visible;
      text-overflow: initial;
    }

    th {
      &:not(:last-child) {
        width: 1%;
      }

      &:first-child {
        width: 50%;
      }

    }
  }
}

// .mat-sort-header-container,
// .th-action {
//   margin-bottom: -0.35rem;
// }