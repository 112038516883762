@import 'company-setup';
@import 'account';
@import 'congrats';
@import 'login';
@import 'layout';
@import 'cases';
@import 'teams';
@import 'schedule';
@import 'empty-list';
@import 'content';
@import 'workability-user-dashboard';
@import 'chat';
@import 'summary';
@import 'subscription';
@import 'followUp';

.is-my-message .message-center-conversation-messages-item__header {
  justify-content: flex-end;
}