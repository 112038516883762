.mat-badge {
  .mat-badge-content {
    border-radius: 4px;
    color: #fff;
    background: $dark;
    @at-root .mat-tab-label-active & {
      background: $primary;
    }
  }
}
