@import '../responsive';

.notification {
  &-menu {
    &.mat-menu-panel {
      min-width: 500px;

      @include respond(phone) {
        min-width: 95%;
      }
    }

    &-item {
      b {
        font-weight: $fw-bold;
        color: $text-dark;
      }

      &,
      p {
        font-size: 14px !important;
      }

      * {
        white-space: normal;
        line-height: 1.3;
      }

      &.mat-menu-item {
        overflow: visible;
        text-overflow: initial;
        box-sizing: border-box;
        height: auto;
        padding: 1.5rem 1rem;

        &:hover {
          .notification-menu-item-task {
            background: $white !important;
          }
        }
      }

      &-icon {
        padding: 4px;
        border-radius: 30px;
        background: $bg-light;
        color: $text-dark;
        min-width: 24px;
        line-height: 24px;
        text-align: center;
        font-size: 18px;
        margin-right: 4px;
      }

      &-task {
        height: auto;
        line-height: auto !important;
        font-size: 12px;
        background: #eff4fa;

        p {
          font-size: 12px !important;
        }

        span {
          line-height: 1;
        }

        .mat-icon {
          line-height: 16px;
          width: 16px;
          height: 16px;
        }
      }

      &-avatar {
        font-size: 10px;
        color: $text-light;

        .rounded-pill {
          color: $text-dark !important;
        }

        .avatar-sm,
        .avatar-overlap {
          width: 20px !important;
          height: 20px !important;
          line-height: 20px !important;

          figure,
          figcaption {
            line-height: 20px !important;
            font-size: 9px !important;
            width: 20px !important;
            height: 20px !important;
          }
        }
      }

      &-unread {
        width: 6px;
        min-width: 6px;
        height: 6px;
        background-color: $orange-1;
        display: inline-block;
        border-radius: 100%;
        align-self: center;
        margin-left: 5px;
      }
    }

    &-body {
      max-height: 70vh;
      overflow: auto;
    }
  }
}

.notification-dropdown-icon.mat-menu-trigger[mat-menu-trigger-custom] {
  width: 40px !important;
}

.mat-badge-natification {
  line-height: 1;
  font-size: 10px;
  top: -5px;
  padding: 3px;
  right: -3px;
  border-radius: 4px;
  color: #fff;

  &.mat-badge-content {
    background: $orange-1;
  }
}

.profile-page-user-details {
  .mat-tab-body-content {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.notification-full-view {
  .notification-menu-item-avatar.justify-content-end {
    justify-content: flex-start !important;
  }

  .notification-menu-item.mat-menu-item {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .notification-menu-item-task {
    max-width: 600px;
  }
}

.snack-bar-push-notification {
  max-width: 400px !important;
  padding: 0 !important;
}
