@import 'src/styles/theme';

.mat-toolbar {
  background: $background-4;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

.toolbar {
  margin-bottom: 1em;
  @include respond(phone) {
    mat-form-field,
    button {
      width: 100%;
    }
    .mat-button-switch + .mat-button-switch {
      margin-top: 1em;
    }
  }
}
