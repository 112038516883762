.navbar-profile {
  &-name {
    padding: 20px;
    > div {
      min-width: calc(100% - 60px);
    }
    .text--small {
      font-size: 12px !important;
    }
  }
  &-dropdown {
    p {
      color: $text-light;
    }
    .avatar {
      width: 50px;
      min-width: 50px;
      height: 50px;
      min-height: 50px;
    }
    .mat-menu-item {
      height: 40px;
      line-height: 40px;
      font-size: $fs-sm;
      font-weight: $fw-regular;
    }
  }
}

.app__actions {
  wr-lang-selector {
    > div {
      display: flex !important;
      height: $navbar-button-icon-wh;
    }
  }
  .mat-menu-trigger .mat-button-wrapper img {
    height: $navbar-button-icon-wh;
    width: $navbar-button-icon-wh;
  }
  .mat-button-base.mat-icon-button {
    height: $navbar-avatar-wh !important;
  }
}

.app-logo {
  img {
    // width: 100%;
    height: 60px;
    transition: 0.1s ease;
    object-fit: cover;
    object-position: left;
  }
}
.is-closed {
  .title__container {
    justify-content: flex-end !important;
  }
  .app-logo img {
    transition: 0.1s ease;
    width: 60px;
    transform: scale(0.8);
  }
}
