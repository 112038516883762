@import 'src/styles/theme';
@import 'src/styles/mixins';
@import 'src/styles/responsive';

.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus+.mat-form-field-label-wrapper .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill+.mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.75em) scale(0.65) perspective(100px) translateZ(0.00106px);
}

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 0.75em;
}

.mat-form-field {
  padding-bottom: 12px;

  .mat-form-field-wrapper {
    display: flex;
    flex-direction: column;

    .mat-form-field-subscript-wrapper {
      @include font-style(500, 14px, normal, $red-1, auto);

      position: relative;
      padding-left: 1px;
      // margin: 8px 0;
      // >div
      display: flex;
      align-items: center;
    }
  }

  &.mat-form-field-invalid {
    .mat-form-field-flex {
      border: 1px solid $red-1;
    }

    &.ng-touched {
      .mat-error {
        display: flex !important;
        flex-wrap: nowrap;

        &:before {
          content: url('~assets/images/svg/invalid-input-icon.svg');
          padding-right: 8px;
          height: 15px;
          width: 16px;
        }
      }
    }
  }

  .mat-form-field-flex {
    border: 1px solid transparent;
    background: $background-2;
    border-radius: 6px !important;
    padding: 0 $input-padding--horizontal !important;
    height: $input-height;
    align-items: center;

    .mat-form-field-infix {
      background: $background-2 !important;
      border: 0;

      .mat-input-element {
        @include font-style(400, $input-fs, normal, $text-light, 1.6);
        // background: $background-2 !important;
        text-decoration: none;
        height: 26px;
      }

      .mat-form-field-label {
        @include font-style(400, $input-fs, normal, $text-light, auto);
      }
    }
  }

  .mat-form-field-underline {
    display: none !important;
  }

  &.mat-form-field-should-float {
    .mat-form-field-label-wrapper {
      // FIXME : add better transition of label between states
      transform: translate(-$input-padding--horizontal, -16px);
    }
  }
}

mat-form-field {
  :has(input[disabled]:not(.hide-lock), input[readonly]:not(.hide-lock), .mat-select-disabled:not(.hide-lock)) {
    position: relative;
    box-sizing: border-box;
    pointer-events: none;

    .mat-form-field-flex {
      overflow: visible;

      >div {
        padding-left: 40px !important;
      }

     &:before {
        content: 'lock';
        font-family: 'Material Symbols Outlined';
        font-weight: normal;
        font-style: normal;
        line-height: $input-height;
        letter-spacing: normal;
        text-transform: none;
        display: inline-block;
        white-space: nowrap;
        word-wrap: normal;
        direction: ltr;
        -webkit-font-smoothing: antialiased;
        color: #00c7b2;
        position: absolute;
        z-index: 1;
        border-right: 1px solid rgba($text-light, 0.1);
        height: $input-height;
        width: 45px;
        text-align: center;
        font-size: 24px;
        left: 0;
        top: 0;
      }
    }

    .mat-empty {
      padding-left: 40px;
    }
  }

  &.hide-lock[disabled],
  &.hide-lock[readonly],
  &.mat-select-disabled.hide-lock {
    .mat-form-field-flex {
      >div {
        padding-left: 0 !important;
      }

      &:before {
        display: none;
      }
    }
  }
}

input[type="time" i]::-webkit-calendar-picker-indicator {
  display: none;
}

.mat-form-field-type-mat-select {
  &.mat-form-field-appearance-fill .mat-select-arrow-wrapper {
    transform: translateY(0%);
  }

  &.mat-form-field .mat-form-field-flex .mat-form-field-infix {
    padding: 0;
  }
}

.mat-form-field-appearance-fill .mat-form-field-infix {
  padding-bottom: 0.25em !important;
}

//   &.mat-form-field .mat-form-field-flex .mat-form-field-infix {
//     padding: 0 !important;
//   }
// }
.mat-form-field {
  &[bg='white'] {
    .mat-form-field-flex {

      &,
      .mat-form-field-infix,
      .mat-form-field-infix .mat-input-element {
        background: #fff !important;
      }
    }

    input,
    textarea,
    select {
      &:-webkit-autofill {

        &,
        &:hover,
        &:focus,
        &:first-line {
          -webkit-box-shadow: 0 0 0 30px $white inset !important;
        }
      }
    }
  }
}

//   .mat-form-field-flex .mat-form-field-infix {
//     padding: 0;
//   }
// }

.custom-mat-list-action.mat-list-base .mat-list-item.custom-mat-list-add .mat-list-item-content {
  padding: 26px 0 0 !important;
}

.custom-mat-list-add {
  .mat-list-item-content {
    align-items: flex-start !important;
    padding-bottom: 0 !important;
    padding-top: 32px !important;

    .mat-button-base.mat-icon-button.mat-primary {
      height: $input-height !important;
      width: $input-height !important;
      border-radius: 6px !important;
      margin-left: 10px;
    }

    .mat-icon-button.mat-primary:not([disabled]) {
      background: $primary !important;

      .mat-button-wrapper {
        color: $white !important;
      }
    }

    .mat-icon-button.mat-primary:is([disabled]) {
      .mat-button-wrapper {
        color: $white !important;
      }
    }
  }

  mat-form-field {
    width: 0 !important;
    flex-grow: 1;
  }
}

.mat-error-custom {
  color: #ff2626;
  font-weight: 500;
  display: flex;
  align-items: center;
  margin-top: 5px;
  position: absolute;
  // top: calc(100% + 40px);
  left: -20px;

  svg {
    margin-right: 0.5rem;
  }
}

.mat-error-notsame,
.mat-error-issame {
  .mat-error-custom {
    top: calc(100% + 15px);
  }

  padding-bottom: 28px !important;

  &.mat-error-required {
    padding-bottom: 28px !important;

    .mat-error-custom {
      top: calc(100% + 40px);
    }
  }
}

.mat-calendar-custom.mat-form-field {
  :has(input[disabled], input[readonly], .mat-select-disabled) .mat-form-field-flex {
    &:before {
      display: none;
    }

    >div {
      padding-left: 0 !important;
    }
  }
}

.search-input {
  .mat-form-field-prefix {
    margin-right: 5px;
  }

  .mat-form-field-suffix {
    cursor: pointer;

    .mat-icon,
    .mat-icon.mat-primary {
      color: $text-lighter !important;
    }
  }
}

textarea.mat-input-element {
  background: $background-2;
  border-radius: 6px !important;
  font-weight: 400 !important;
  font-size: 15px !important;
  font-style: normal !important;
  color: #666 !important;
  line-height: 20px !important;
  text-decoration: none;
  padding: 1rem;
  box-sizing: border-box;
}

.no-padding-bottom-container {
  .mat-form-field {
    padding-bottom: 0 !important;
  }
}

textarea.mat-input-element {
  padding: 0 !important;
  margin: 0 !important;
}

textarea.mat-input-element.text-area-custom {
  padding: 1rem !important;
}

.mat-radio-group-custom {
  min-width: 145px;
  display: flex;
  align-items: center;

  .mat-radio-button {
    ~.mat-radio-button {
      margin-left: 0;
    }

    &.button-type {
      &:first-child .mat-radio-label {
        border-radius: 6px 0 0 6px;
      }

      &:last-child .mat-radio-label {
        border-radius: 0 6px 6px 0;
      }

      &:not(:last-child) .mat-radio-label {
        border-right: 0;
      }

      .mat-radio-label {
        height: 26px;
        padding: 0 8px;
        width: auto;
        background: transparent;
        border: 1px solid $gray-5;
        color: $primary;
        border-radius: 0;

        &:hover {
          background: #f5f5f5;
        }
      }

      &.mat-radio-checked .mat-radio-label {
        background-color: $primary;
        color: $white;
      }
    }
  }

  &-priority {
    .mat-radio-button.button-type {
      .mat-radio-label {
        color: $text-light;
      }

      &:nth-child(2) {
        &.mat-radio-checked .mat-radio-label {
          background: $gray-1;
          // border-color: $gray-3;
          color: $text-dark;
        }
      }

      &:nth-child(3) {
        &.mat-radio-checked .mat-radio-label {
          background: $orange-1;
          border-color: $orange-1;
        }
      }
    }
  }
}

.pointer-event-none {
  pointer-events: none;
}

.percentage_slider__form {
  margin-bottom: 2rem !important;
}

.slider-percentage {
  margin-bottom: 0;
  pointer-events: none !important;
  text-align: center !important;
  height: 70px;
  padding-bottom: 0;

  &.mat-form-field .mat-form-field-flex {
    height: auto;
    background: transparent;

    .mat-form-field-infix {
      background: transparent !important;
    }

    .mat-input-element {
      font-size: 50px !important;
      height: auto;
      font-weight: 500 !important;

      &:after {
        content: '%';
      }
    }
  }
}

.mat-slide-toggle {
  &-bar {
    width: 46px !important;
    height: 24px !important;
    border-radius: 24px !important;

    .mat-slide-toggle-thumb-container {
      top: 2px;
      left: 2px;
    }

    &:after,
    &:before {
      line-height: 24px;
      position: absolute;
    }

    &:after {
      content: '\292C';
      right: 7px;
      color: #fff;
    }

    &:before {
      content: '\2713';
      left: 7px;
      color: #fff;
    }
  }

  &.mat-checked {
    .mat-slide-toggle-thumb {
      background-color: #fff;

      &-container {
        transform: translate3d(21px, 0, 0) !important;
      }
    }
  }
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(0, 199, 178);
}

wr-slide-toogle-list {
  .mat-slide-toggle-label-before {
    margin-right: 1rem;

    .mat-slide-toggle-content {
      line-height: 18px;
      flex-grow: 1;
    }

    label {
      line-height: 18px;
    }
  }

  .multi-button-select-custom-big {
    flex-flow: column;
    display: flex;
    margin-left: auto;
    margin-right: auto;

    @include respond(phone) {
      margin-left: 0;
      margin-right: 0;
    }
  }
}

.mat-chip-list-wrapper {
  background-color: $bg-light;
  border-radius: $border-radius;
  padding: 0 6px;

  .mat-chip.mat-standard-chip {
    background-color: $primary;
    color: #fff;
  }

  .mat-chip.mat-standard-chip.mat-chip-orange {
    background-color: $orange-1;
    color: #fff;
  }

  .mat-form-field {
    padding-bottom: 0;
  }
}

.week__day__timepickers+.week__day__timepickers input {
  border-radius: 0 0 $border-radius $border-radius;

  @media (max-width: $breakpoint--big-screen) {
    border-radius: 0;
  }
}

.iti {
  border: 0 !important;
}

.iti:focus-visible {
  outline: none !important;
}

.tel-input-custom:focus-visible {
  outline: none !important;
}

.tel-input-custom {
  font-weight: 400 !important;
  font-size: 1rem !important;
  font-style: normal !important;
  color: #666 !important;
  line-height: 20px !important;
  text-decoration: none;
  height: 22px;
  border: 0;
  background: transparent;
}

.mat-error {
  color: #ff2626 !important;
}

.mat-error:before {
  content: url('~assets/images/svg/invalid-input-icon.svg');
  padding-right: 8px;
  height: 15px;
  width: 16px;
}

.mat-form-field-hint-spacer {
  flex: none !important;
}

.multi-button-select__form__checkbox-buttons__container {
  .mat-slide-toggle-content {
    max-width: 500px;
  }

  .mat-slide-toggle-label {
    justify-content: center;
  }
}

.controls__input {
  .mat-chip-list-wrapper {
    min-height: 100px;
    align-items: flex-start;
    padding: 4px;
  }
}

.multi-button-select__form__checkbox-buttons__container {
  max-width: 75%;
  width: 65%;

  @include respond(phone) {
    max-width: 100%;

    &,
    tr,
    td {
      display: block !important;
      width: 100%;
    }

    tr {
      margin-bottom: 1rem;
    }
  }
}

// wr-control-selector {
//   display: flex;
//   justify-content: center;
// }
wr-location-textbox {

  // display: flex;
  //   flex-direction: column;
  .textbox__form-field {
    width: 100%;
  }

  mat-radio-group {
    display: flex;
    flex-direction: column;

    .mat-radio-button {
      margin-bottom: 1rem;

      &~.mat-radio-button {
        margin-left: 0;
      }
    }

    .mat-radio-label {
      // justify-content: center;
    }
  }
}

.message-center-channels-search {
  padding: 2.5em 1em 1em;
  box-sizing: border-box;
  width: 100%;

  .mat-form-field-flex {
    border: 1px solid $border-color-1;
  }
}

.editable-label-input {
  padding-bottom: 0 !important;

  .mat-form-field-wrapper {
    flex-direction: unset !important;
    padding-bottom: 0 !important;
  }
}

.message-center-channels-search {
  padding: 2.5em 1em 1em;
  box-sizing: border-box;
  width: 100%;

  .mat-form-field-flex {
    border: 1px solid $border-color-1;
  }
}

input#phone::placeholder {
  opacity: 50%;
}