.mat-card {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1) !important;
  border-radius: 6px !important;

  .mat-card-header-text {
    width: 100%;
  }

  .mat-card-header-text,
  .mat-card-actions {
    margin: 0;
    padding: 0;
  }
}

.terms-condition-container {
  margin-right: -20px;
  overflow: auto;
  padding-right: 20px;
  max-height: calc(100vh - 550px);
  margin-bottom: 10px;

  p {
    font-size: 13px !important;
  }

  h3 {
    font-weight: 600 !important;
    font-size: medium !important;
  }

  h4,
  h5,
  h6,
  li,
  p {
    font-weight: 400 !important;
    font-size: small !important;
  }

  a {
    font-size: small !important;
  }
}