@import 'src/styles/theme';
@import 'src/styles/mixins';
@import 'src/styles/breakpoints';

.mat-button {
  &.workplace-action {
    padding: 0 !important;
  }

  &-item-icon {
    .material-icons {
      font-size: 18px;
      display: inline;
    }
  }
}

@mixin secondary-button() {
  border: 1px solid $green-1 !important;
  background: $background-4 !important;

  &:hover:not([diabled]) {
    border: 1px solid $green-2 !important;
    background: $background-4 !important;

    .mat-button-wrapper {
      color: $green-1;
    }
  }

  &:not(:hover):not(:active) {
    box-shadow: none !important;
  }

  &:not(:hover):not(:active) {
    .mat-button-wrapper {
      color: $green-1;
    }
  }

  &.mat-stroked-button,
  &.mat-stroked-button:hover {
    background: transparent !important;

    &.mat-button-disabled {
      border: 1px solid $text-lighter !important;

      &:hover .mat-button-wrapper,
      .mat-button-wrapper {
        color: $text-lighter !important;
      }
    }
  }
}

.mat-button-base {
  height: $button-height;
  border-radius: 8px !important;
  line-height: 19.5px !important;
  background: $btn-bg !important;
  color: #fff;
  padding-left: $btn-padding !important;
  padding-right: $btn-padding !important;
  border: 2px solid transparent;

  &:hover,
  &:active {
    color: $text-white !important;
    background: $btn-bg-hover !important;
  }

  &.mat-button-border {
    border: 1px solid $border-color-1;
  }

  &.mat-flat-button,
  &.mat-icon-button {
    &.mat-primary {
      color: #fff;
    }
  }

  &.mat-button-disabled {
    background: $gray-1 !important;
    box-shadow: none !important;
    cursor: not-allowed !important;
    color: $text-white !important;
  }
}

.button--small {
  $line-height: 17px;

  min-width: 120px !important;
  height: 30px;
  box-shadow: 0 10px 30px -10px rgba(0, 199, 178, 0.4);
  line-height: $line-height !important;

  &:active {
    box-shadow: 0 10px 20px -5px rgba(0, 199, 178, 0.7);
  }

  .mat-button-wrapper {
    @include font-style(600, 12px, normal, $text-white, $line-height);
    color: #fff;
  }
}

.button--middle {
  $line-height: 17px;

  min-width: 140px !important;
  height: 40px;
  box-shadow: 0 10px 30px -10px rgba(0, 199, 178, 0.4);

  &:active {
    box-shadow: 0 10px 20px -5px rgba(0, 199, 178, 0.7);
  }

  .mat-button-wrapper {
    @include font-style(600, 12px, normal, $text-white, $line-height);
  }
}

.button--large {
  $line-height: 24.5px;

  min-width: 250px !important;
  height: 70px;
  box-shadow: 0 20px 30px -15px rgba(0, 199, 178, 0.4);
  line-height: $line-height !important;

  &:active {
    box-shadow: 0 20px 30px -15px rgba(0, 199, 178, 0.7);
  }

  .mat-button-wrapper {
    @include font-style($fw-regular, 20px, normal, $text-white, $line-height);
  }
}

.mat-stroked-button {
  @include secondary-button();
}

.mat-icon-button-left {
  width: 24px !important;
  min-width: 24px !important;
  height: 24px !important;
  border-radius: 12px !important;
  background-color: red !important;
  box-shadow: none !important;
  //padding: 10px;

  &.mat-button-left-base {
    &.no-background {
      background: transparent;
      box-shadow: none;

      img,
      .mat-icon {
        fill: $green-1;
        color: $green-1;
      }
    }

    &.secondary {
      @include secondary-button();
    }
  }
}

.mat-button-base.mat-icon-button {
  width: $button-icon-wh !important;
  min-width: $button-icon-wh !important;
  height: $button-icon-wh !important;
  border-radius: $button-icon-wh !important;
  padding: 10px;
  font-size: 16px;

  &.mat-primary-light {
    color: $primary !important;

    &:hover {
      background: rgba($btn-bg, 0.1);
    }
  }

  &.mat-dark:hover {
    background: rgba($dark, 0.1);
  }

  &.no-background {
    background: transparent !important;
    box-shadow: none !important;

    img,
    .mat-icon {
      fill: $green-1;
      color: $green-1;
    }
  }

  &.secondary {
    @include secondary-button();
  }

  &.edit-icon i,
  &.edit-icon .mat-icon {
    line-height: 20px !important;
  }

  // &:not(.mat-menu-trigger):hover {
  //   background-color: $gray-5 !important;
  // }
}

.text-button,
.mat-icon-button {
  min-width: unset !important;
  height: 24px !important;
  background: transparent !important;
  box-shadow: none !important;
  padding: 0 !important;

  &:hover,
  &:active {
    box-shadow: none !important;
  }

  &:hover {
    background: transparent !important;
  }

  .mat-button-wrapper {
    @include font-style($fw-regular, 15px, normal, $text-dark, 24px);
  }

  &.primary {
    .mat-button-wrapper {
      color: $green-1 !important;
    }
  }

  &.secondary {
    .mat-button-wrapper {
      color: $orange-1 !important;
    }
  }

  &.text-white {
    .mat-button-wrapper {
      color: #fff !important;
    }
  }

  &.text-dark {
    .mat-button-wrapper {
      color: #666 !important;
    }
  }

  &.has-arrow {
    $arrow-width: 35px;
    $arrow-padding--left: 15px;
    $arrow-with-padding: calc(#{$arrow-width} + #{$arrow-padding--left});

    padding: ($arrow-padding--left/2.5) $arrow-with-padding (
      $arrow-padding--left/2.5) $arrow-padding--left !important;
    position: relative;
    height: auto !important;

    &:after {
      position: absolute;
      right: 15px;
      top: 10px;
      // height: 100%;
      display: flex;
    }

    &.primary {
      &:after {
        content: url('~assets/images/svg/further-arrow-primary.svg'
        );
    }
  }

  &.secondary {
    &:after {
      right: 25px;
      top: 7px;
      border-radius: 50%;
      background-color: $orange-1;
      width: 20px;
      height: 20px;
      content: url('~assets/images/svg/play_arrow.svg');
    }
  }
}
}

.add-action-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 54px;
  margin-right: 40px;
}

.add-action-container__btn {
  min-width: auto !important;
  background-color: $btn-bg !important;
  max-width: 100%;
  height: 45px;
  width: 45px;
  padding: 0 !important;
}

.add-action-container__label {
  font-size: 30px;
  font-weight: $fw-regular;
  margin-left: 11px;
}

@media (max-width: $breakpoint--tablet) {
  .add-action-container {
    margin-top: 35px;
  }

  .add-action-container__btn {
    height: 30px;
    width: 30px;
  }

  .add-action-container__label {
    font-style: normal !important;
    font-weight: $fw-regular !important;
    font-size: 18px !important;
    line-height: 22px !important;
    margin-left: 27px;
  }
}

.mat-button-base {
  &.mat-button-white {
    background: #fff !important;
    color: $primary;

    &.mat-button-switch {
      border: 1px solid transparent !important;

      &:hover {
        border-color: rgba($primary, 0.2) !important;
        color: $primary !important;
      }
    }

    &:hover {
      color: $text-dark !important;
      background-color: #fff !important;
    }
  }

  &.mat-stepper-previous {

    &,
    &:hover {
      color: $text-dark !important;
      background-color: #fff !important;
    }
  }
}

.mat-button {
  &-secondary-fill {
    border-color: $background-2;
    background-color: $background-2 !important;
    color: $text-light;
  }

  &-active {
    background-color: $btn-bg-hover !important;
    border-color: $btn-bg-hover !important;
    color: #fff !important;
  }
}

.mat-button-square {
  height: auto;
  min-height: 180px;
  width: 180px;
  border-radius: 24px !important;

  &.border-radius-4 {
    border-radius: 4px !important;
  }

  &.medium {
    min-height: 160px;
    width: 160px;
  }

  &.radio-custom {
    display: inline-flex;
    padding: 0 !important;

    .mat-icon {
      width: 100% !important;
    }

    &:hover,
    &.mat-radio-checked {
      background: $primary !important;

      .mat-radio-label,
      .mat-icon {
        color: $white;
      }

      .mat-radio-container {
        display: none;
      }
    }

    .mat-radio-label {
      min-width: auto;
      height: auto;
      background: transparent !important;
      padding: 25px !important;


      &-content {
        white-space: normal;
        line-height: 1.6;
        padding-left: 0 !important;
      }
    }


  }

  @include respond(phone) {
    min-width: calc(50% - 10px) !important;
    min-height: 130px;
    width: 130px;
  }

  @include ratio-125 {
    min-height: 150px;
    width: 150px;
    padding: 10px !important;
    border-radius: 18px !important;
  }

  &.mat-checkbox {
    padding: 0 !important;

    .mat-checkbox-layout {
      box-sizing: border-box;
      padding: 25px !important;
      min-height: 150px;
      display: flex;
      align-items: center;

      @include ratio-125 {
        min-height: 150px;
        padding: 10px 5px !important;
      }

    }
  }

  .mat-icon {
    font-size: 45px;
    margin-bottom: 10px;
    line-height: 1;
    height: auto;
    width: auto;
    color: $primary;

    @include ratio-125 {
      font-size: 35px;
    }
  }

  &,
  &.mat-button-base {
    line-height: 1.5 !important;
    background-color: rgba($btn-bg, 0.1) !important;

    &.mat-button-square-white {
      background-color: #fff !important;

    }

    p {
      font-weight: $fw-regular !important;
      margin-bottom: 0;
      font-size: $fs-sm !important;
      white-space: normal;
    }

    &:hover,
    &.mat-radio-checked {
      box-shadow: $shadow;
      background-color: $green-2 !important;

      .mat-icon,
      p {
        color: $white !important;
      }
    }

    &.mat-button-active,
    &:active,
    &.mat-checkbox-checked {
      box-shadow: $shadow;
      background-color: $green-1 !important;

      .mat-icon,
      p {
        color: $white !important;
      }
    }

    &.mat-checkbox-disabled {
      background-color: $gray-1 !important;

      .mat-icon,
      p {
        color: #999 !important;
      }

      &:hover {
        box-shadow: none;
      }
    }

    &.mat-radio-button {
      display: flex;
      align-items: center;

      .mat-radio-label-content {
        width: 100%;
        padding: 0;
      }

      .mat-radio-container {
        display: none;
      }

      &.blank {
        border: 1px dashed $text-light;
        background: transparent !important;

        &:hover {

          &,
          p,
          .mat-icon {
            color: $text-light !important;
          }

        }

        .mat-icon {
          color: $text-light;
        }
      }

      @include respond(phone) {
        display: inline-flex;
        margin-right: 10px;
        margin-bottom: 10px;
        float: left;
      }
    }

    .mat-checkbox {
      &-layout {
        width: 100%;
        justify-content: center;
      }

      &-inner-container {
        display: none !important;
      }
    }

    .mat-checkbox-disabled {
      background-color: $gray-1 !important;

      &:hover {
        background-color: $gray-1 !important;
      }
    }
  }
}

.mat-radio-button~.mat-radio-button {
  margin-left: 0.25rem;

  @include respond(phone) {
    margin-top: 0 !important;
  }
}

.\!cursor-pointer {
  cursor: pointer !important;
}

.mat-button-search.mat-button-base.mat-icon-button {
  background: $btn-bg !important;
  width: 40px !important;
  height: 40px !important;

  .mat-icon {
    color: #fff;
  }
}

///animation
.label {
  opacity: 1;
  transform: translateY(0);
  transition: transform 0.2s cubic-bezier(0.17, 0.67, 0.79, 1.03);
}

.loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
  opacity: 0;
  transform: translateY(5px);
  transition: transform 0.2s ease-in-out;

  &-dots::after {
    content: '...';
    position: sticky;
    right: auto;
    font-size: x-large;
    opacity: 0;
    animation: loading-dots-animation 1.5s infinite;
  }
}

// .btn-group {

//   .mat-button-base+.mat-button-base:not(:last-child) {
//     border-radius: 0 !important;
//   }

//   .mat-button-base:first-child {
//     border-top-right-radius: 0 !important;
//     border-bottom-right-radius: 0 !important;
//   }

//   .mat-button-base:last-child {
//     border-top-left-radius: 0 !important;
//     border-bottom-left-radius: 0 !important;
//   }
// }

.mat-button-icon-start {
  mat-icon {
    font-size: 22px;
    line-height: 22px;
    font-weight: 300;
  }
}

.icon-circle-hover {
  &:hover {
    background: $text-light;
  }
}