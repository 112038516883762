@import 'src/styles/theme';

$menu-space: 12px;
$menu-item-height: 80px;

.menu-closed {
  .app-sidenav__company-menu {
    transform: translate(calc(#{$closed-nav-width} + #{$menu-space}), calc(-1 * #{$sidenav-company-item-height}));
  }

  .app-sidenav__new-action-menu {
    transform: translate(calc(#{$closed-nav-width} + #{$menu-space}),
      calc(-1 * #{$menu-item-height} + #{$nav-list-free-space}));
    margin: 0 110px 0 -5px;
    max-width: 90% !important;
  }
}

.menu-opened {
  .app-sidenav__company-menu {
    transform: translate(calc(#{$opened-nav-width} + #{$menu-space}), calc(-1 * #{$sidenav-company-item-height}));
  }

  .app-sidenav__new-action-menu {
    transform: translate(calc(#{$opened-nav-width} + #{$menu-space}),
      calc(-1 * #{$menu-item-height} + #{$nav-list-free-space}));
  }
}

.app-sidenav__company-menu,
.app-sidenav__new-action-menu {
  visibility: hidden;
  max-width: unset !important;

  &.is-showed {
    visibility: visible;
  }
}

.app-sidenav__company-menu {
  width: 336px;
}

.app-sidenav__new-action-menu {
  // width: 320px;
}