@import 'src/styles/theme';
@import 'src/styles/breakpoints';
@import '../responsive';

.datepicker__form-field {
  &.mat-form-field-disabled {
    .mat-icon {
      fill: $gray-3 !important;
    }
  }

  &.mat-form-field-disabled {
    .mat-form-field-flex {
      background: $background-2;
    }
  }

  .mat-form-field-flex {
    position: relative;
    border-radius: 8px !important;
    height: auto;
    padding: 0 !important;

    .mat-form-field-infix {
      height: 100%;
      padding: 0;
      position: absolute;
      left: 50px;
      display: flex;
      align-items: center;
      border-left: 1px solid $border-color-1;
      width: auto;
      border-top-right-radius: 8px !important;
      border-bottom-right-radius: 8px !important;

      @media (max-width: $breakpoint--small-screen , $breakpoint--tablet) {
        padding: 0;
      }

      .mat-input-element {
        margin-left: 18px;
      }

      .mat-date-range-input-container {
        justify-content: center;
      }
    }
  }

  .mat-form-field-label-wrapper {
    transform: translate(-52px, -5px) !important;
  }
}

.mat-datepicker-toggle {
  .mat-button-base {
    background: transparent !important;
    box-shadow: none !important;

    .mat-datepicker-toggle-default-icon {
      fill: $green-1;
    }
  }
}

.mat-datepicker-content {
  .mat-calendar-header {
    .mat-button-base {
      min-width: auto !important;
      background: unset !important;
      height: 32px !important;

      &:first-of-type {
        margin-left: 16px;
        background: $green-2 !important;
      }

      &:not(:first-of-type) {
        &.mat-icon-button {
          width: 40px !important;
          height: 32px !important;

          &:after {
            display: none;
          }

          &:before {
            display: block;
            content: url('~assets/images/svg/vector.svg');
          }

          &.mat-calendar-previous-button {
            &:before {
              transform: rotate(-90deg);
            }
          }

          &.mat-calendar-next-button {
            &:before {
              transform: rotate(90deg);
            }
          }
        }

        &:focus {
          background: transparent;
        }
      }

      .mat-calendar-arrow {
        border-top-color: $background-4;
      }
    }
  }
}

.mat-calendar-custom {
  padding-right: 35px;
  margin-right: 10px;

  @include respond(phone) {
    margin-right: 0;
  }

  .mat-calendar-custom-input.mat-form-field {
    .mat-form-field-flex {
      border-radius: 6px 0 0 6px !important;
    }
  }

  &-clear.mat-icon {
    cursor: pointer;
    background-color: #fff;
    width: 35px;
    height: $input-height;
    border-radius: 0 6px 6px 0;
    position: absolute;
    right: 0;
    top: 0;
    line-height: $input-height;
    text-align: center;
    font-size: 20px;
    color: $text-lighter;
    // color: $primary;
  }

  &:hover {
    color: $primary;
  }

  &-input.mat-form-field
    :has(input[disabled], input[readonly], .mat-select-disabled) {
    .mat-form-field-flex {
      &:before {
        display: none;
      }

      > div {
        padding-left: 0 !important;
      }
    }

    .mat-empty {
      padding-left: 10px;
    }
  }

  .mat-form-field-label-wrapper {
    top: -5px !important;
  }
}

.mat-date-range-input-container {
  font-size: $input-fs;
  padding-left: 6px;
}

.mat-calendar-custom
  .mat-form-field
  .mat-form-field-flex
  .mat-form-field-infix
  .mat-form-field-label {
  color: $date-picker-placeholder-color !important;
}

@include ratio-125 {
  .mat-calendar-body-cell,
  .mat-calendar-body-label {
    padding: 1.19rem !important;
  }

  .mat-calendar-period-button {
    padding: 0 1.19rem !important;
  }

  .date-range__container .date-range__section {
    min-height: auto !important;
    max-width: 320px !important;
  }
}

.event-chip {
  text-align: center;
}

.cal-month-view,
.cal-week-view,
.cal-day-view {
  background: transparent;
}

.cal-month-view,
.cal-week-view,
.cal-day-view {
  .cal-header .cal-cell,
  .cal-header b {
    font-weight: 500;
    font-size: 12px;
    color: #999999;
  }
}

.cal-month-view .cal-day-number {
  opacity: 1;
  font-weight: 500;
}

.cal-month-view,
.cal-week-view,
.cal-day-view {
  .cal-header,
  .cal-cell-row.cal-header:hover,
  .cal-cell-row.cal-header .cal-cell:hover {
    background: $bg-light !important;
  }
}

.cal-week-view .cal-day-headers span,
.cal-week-view .cal-time {
  font-weight: 500;
  opacity: 1;
}

.cal-week-view .cal-day-headers .cal-header:first-child {
  border-left: 1px solid transparent !important;
}

.cal-week-view .cal-day-headers .cal-header:not(:last-child) {
  border-right: 1px solid transparent !important;
}

.cal-days {
  background-color: $white;
  border-radius: $border-radius;
}

.cal-week-view .cal-time-events {
  border-radius: $border-radius;
  border-top: 1px solid #e1e1e1 !important;
}

.cal-week-view .cal-day-headers {
  border-color: transparent;
}

.cal-week-view {
  border-color: transparent;
}

// .cal-day-view .cal-day-column {
//   border-top: 1px solid #e1e1e1;
// }

.cal-month-view .cal-open-day-events {
  background-color: #ededed;
  box-shadow: none;
  color: $text-light;
  padding: 30px;
}

.cal-drag-over {
  font-weight: 500;
}

.cal-open-day-events {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  gap: 2px;

  > div {
    margin-bottom: 0.5rem;
    display: inline-flex;
    background: #fff;
    border-radius: 20px;
    padding: 5px 5px 5px 12px;
  }
}

.cal-month-view .cal-open-day-events .cal-event {
  top: 6px;
  left: -3px;
}

.cal-month-view .cal-event {
  width: 6px;
  height: 6px;
}

.cal-month-view .cal-event-title:hover {
  text-decoration: none;
}

.cal-time-events {
  background-color: #fff;
}
