@import 'src/styles/theme';

.mat-snack-bar-container {
  &.success {
    background: $green-1;
  }

  &.warning {
    background: $orange-1;
  }

  &.error {
    background: $red-1;
  }
}
