@import '../responsive';

.mat-card-title {
  text-align: center;
  margin-bottom: 20px;
  color: $primary;

  &-sm {
    font-size: 14px;
    color: $text-dark;
    font-weight: $fw-regular;
  }
}

.app-sidenav__company-menu .mat-menu-content:not(:empty) {
  padding: 0 !important;
}

wr-entry {
  wr-lang-selector {
    text-align: right;
  }
}

.mfa-input-text {
  .mat-form-field .mat-form-field-flex {
    border-radius: 6px 0 0 6px !important;

    @include respond(phone) {
      border-radius: 6px !important;
    }
  }

  [wrbutton] {
    border-radius: 0 6px 6px 0 !important;

    @include respond(phone) {
      border-radius: 6px !important;
    }
  }
}

.company-edit-icon {
  @include respond(phone) {
    position: absolute;
    top: 1rem;
    right: 1rem;
  }
}