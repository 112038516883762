.swal2-styled {
  &.swal2-deny {
    border: 1px solid $primary !important;
    background-color: transparent !important;
    color: $primary !important;

    &:hover {
      background-color: rgba($primary, 0.1) !important;
    }
  }
}

.swal2-popup {
  max-width: 900px !important;
  width: 100% !important;
}

// .swal2-container.swal2-backdrop-show,
// .swal2-container.swal2-noanimation {
//   background: rgba(0, 0, 0, 0.7) !important;
// }

// .swal2-actions:not(.swal2-loading) .swal2-styled:hover {
//   background-image: linear-gradient(
//     rgba(0, 0, 0, 0.02),
//     rgba(0, 0, 0, 0.02)
//   ) !important;
// }
.swal2-styled.swal2-default-outline,
.swal2-styled.swal2-deny {
  &:focus {
    box-shadow: none !important;
  }
}

.swal2-styled.swal2-deny:hover {
  background: rgba(#000, 0.04) !important;
}

.swal2-styled.swal2-default-outline {
  background: $btn-bg !important;

  &:hover {
    background: $btn-bg-hover !important;
  }
}

.swal2-icon {
  margin-top: 0 !important;
  // .swal2-question {
  border-color: rgba($primary, 0.25) !important;
  color: $primary !important;
  // }
}

.swal2-success-ring {
  margin-top: 0 !important;
  // .swal2-question {
  border-color: rgba($primary, 0.25) !important;
  color: $primary !important;
  // }
}

.swal2-icon.swal2-success [class^='swal2-success-line'],
.swal2-styled.swal2-confirm {
  background-color: $primary !important;

  // }
}

.swal2-styled.swal2-confirm:focus {
  box-shadow: 0 0 0 3px rgba($primary, 0.5) !important;
}

.swal2-container.swal2-center {
  >.swal2-popup {
    padding: 2rem;
  }

  h2 {
    margin-bottom: 0;
  }

  .swal2-html-container {
    margin: 0;
  }
}

.mat-typography .swal2-container.swal2-center h2,
.swal2-container.swal2-cente .swal2-html-container {
  font-size: 16px !important;
  line-height: 1.8 !important;
  font-weight: $fw-regular !important;
  color: $text-black;
}

.swal2-container.swal2-center {
  z-index: 9999 !important;
}

button.swal2-styled {
  @include respond(phone) {
    width: 100%;
  }
}