@mixin font-style($weight, $size, $style, $color, $line-height) {
  font-weight: $weight !important;
  font-size: $size !important;
  font-style: $style !important;
  color: $color !important;
  line-height: $line-height !important;
}

@mixin ratio-150 {

  @media (min-resolution: 1.5dppx),
  (min-resolution: 144dpi),
  (-webkit-min-device-pixel-ratio: 1.5),
  (-o-min-device-pixel-ratio: 3/2) {
    @content;
  }
}

@mixin ratio-125 {

  @media (-webkit-min-device-pixel-ratio: 1.25),
  (-o-min-device-pixel-ratio: 5/4) {
    @content;
  }
}

@mixin fs_ratio-125($value, $percent: $ratio-125-value-percentage) {
  font-size: calc(#{$value} - calc((#{$value} * #{$percent}) / 100)) !important;
}