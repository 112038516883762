$page-padding: 30px;

.wa-container {
  padding: $page-padding;
  @include media-breakpoint-down(sm) {
    padding: 20px;
  }
}
.page-title {
  font-weight: $fw-regular !important;
  margin: 0.6rem 0 !important;
  color: $text-black !important;
}
