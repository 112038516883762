.wa-stepper-outer {
  @at-root mat-horizontal-stepper[wa-stepper-outer] {
    background-color: transparent;
  }
  .mat-horizontal-stepper-header-container {
    justify-content: center;
  }
  .mat-horizontal-stepper-header .mat-step-icon {
    margin-right: 0;
  }
  .mat-step-icon .mat-icon {
    font-size: 12px;
    text-align: center;
    line-height: 16px;
  }
  .mat-step-header {
    flex-flow: column;
    overflow: visible;
    &:not(:last-child):before {
      content: '';
      width: 100%;
      background: $primary;
      height: 1px;
      position: absolute;
      left: 50%;
      top: 12px;
    }
    &[ng-reflect-selected='false']:before {
      background: #bababa;
    }
  }
  .mat-step-label {
    font-size: 10px;
    text-align: center;
    font-weight: $font-weight-bold;
    color: $text-light !important;
  }
  .mat-stepper-horizontal-line {
    display: none;
  }
  .mat-step-header.cdk-keyboard-focused,
  .mat-step-header.cdk-program-focused,
  .mat-step-header:hover {
    background-color: transparent;
  }
  .mat-step-icon,
  .mat-step-icon-selected {
    background-color: $gray-3;
    color: #fff;
  }
  .mat-step-icon-selected,
  .mat-step-icon-state-done,
  .mat-step-icon-state-edit {
    background-color: $primary;
  }
}

.wa-stepper-inner {
  .mat-stepper-horizontal-line {
    display: none;
  }
  .mat {
    &-horizontal {
      &-stepper-header {
        height: 24px;
        padding: 0 3px;
        flex-grow: 1;
        &-container {
          margin-bottom: 20px;
          gap: 15px;
        }
      }
      &-content-container {
        padding: 0 !important;
        // overflow: visible;
      }
    }
    &-step {
      &-header {
        border-bottom: 3px solid $gray-3;
        font-weight: $font-weight-bold;
        &:hover {
          background-color: transparent;
        }
        &[aria-selected='true'] {
          border-color: $primary;
          .mat-step {
            &-icon,
            &-label {
              // font-weight: $font-weight-bold;
              &-selected {
                color: $primary;
              }
            }
          }
        }
        &[aria-labelledby='filled-previously'] {
          border-color: $primary;
          .mat-step {
            &-icon,
            &-label {
              // font-weight: $font-weight-bold;
              color: $primary;
              &-selected {
                color: $primary;
              }
            }
          }
        }
        .mat-step-icon {
          color: $text-light;
        }
      }
      &-icon {
        &-state {
          &-number,
          &-selected,
          &-done,
          &-edit {
            background-color: transparent;
            width: auto;
            height: auto;
            padding: 3px;
          }
        }
      }
      &-label {
        font-size: 12px;
        font-weight: $font-weight-bold;
        color: $text-light;
      }
    }
    &-form-field {
      width: 100%;
    }
  }
}

.mat-step-header.cdk-keyboard-focused,
.mat-step-header.cdk-program-focused,
.mat-step-header:hover {
  background-color: transparent;
}
.mat-step-header {
  pointer-events: none !important;
}

.wizard {
  width: 100%;
  text-align: center;
  ul {
    list-style: none;
    padding: 0;
    @include respond(phone) {
      overflow: auto;
      display: flex;
      justify-content: center;
    }
  }

  li {
    padding: 0 15px;
    display: inline-block;

    position: relative;
    &:not(:last-child):after {
      content: '';
      width: 100%;
      background: #bababa;
      height: 1px;
      position: absolute;
      left: 50%;
      top: 17px;
      z-index: 2;
    }
    &:not(:first-child):before {
      content: '';
      width: 50%;
      background: #bababa;
      height: 1px;
      position: absolute;
      right: 50%;
      top: 17px;
    }
    &.done,
    &.active {
      .wizard-icon {
        background-color: $primary;
        border-color: $primary;
      }
      &:after,
      &:before {
        background: $primary;
      }
    }
    &.done {
      .wizard {
        &-icon {
          background-color: $white;
          border-color: $primary;
          mat-icon {
            color: $primary;
          }
          content: 'done' !important;
        }
        &-text {
          color: $primary;
        }
      }
    }
  }
  &-icon {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    background-color: #bababa;
    border: 1px solid #bababa;
    margin: 0 auto 5px;
    position: relative;
    z-index: 9;

    mat-icon {
      color: #fff;
      font-size: 18px;
      line-height: 30px;
    }
  }
  &-text {
    font-size: 10px;
    font-weight: 600;
    @include respond(phone) {
      display: none;
    }
  }
}
