.small {
  font-size: 14px !important;
}
.fs-12 {
  font-size: 12px !important;
}
.no-bg {
  background-color: transparent !important;
  &-hover {
    &.mat-button-base.mat-icon-button:not(.mat-menu-trigger):hover {
      background-color: transparent !important;
    }
    .mat-button-focus-overlay {
      opacity: 0 !important;
    }
  }
}

.mat-icon-button {
  &.text-primary {
    .mat-button-wrapper {
      color: $primary !important;
    }
  }
}

.bg-primary-light {
  background: rgba($primary, 0.1) !important;
}

.bg-danger-light {
  background: rgba($danger, 0.1) !important;
}
