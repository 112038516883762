@import 'theme';
@import 'angular-material/material-theme';
@import 'angular-material/material-typography';
@import 'typography';
@import 'mixins';
@import 'autocomplete';
@import 'breakpoints';
@import 'utilities';
@import 'responsive';
@import '../../node_modules/angular-calendar/scss/angular-calendar';

body,
html {
  margin: 0;
  background: $background-3;
  height: auto;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
  text-size-adjust: none;
}

$enable-negative-margins: true;

@import 'node_modules/bootstrap/scss/mixins';
@import 'node_modules/bootstrap/scss/bootstrap-grid.scss';
@import 'node_modules/bootstrap/scss/bootstrap-utilities.scss';

@import 'views';
@import 'components';
@import 'features/features.main';

@include respond(phone) {
  .welcome-message {
    font-size: 24px !important;
  }
}

wr-mobile-menu {
  display: block;
  height: calc(100% - 130px);
}

.mobile-profile-menu {
  background: #fff;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  z-index: 99999;
  right: -100%;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;

  &.active {
    transform: translate3d(-100%, 0, 0);
    animation-timing-function: 1s ease-in;
  }
}

.mobile-menu-switch {
  font-size: 9px !important;
}

.bottom-nav-space {
  margin-bottom: 60px;

  + .cdk-global-overlay-wrapper {
    height: calc(100% - 65px);
  }
}

.mobile-profile-menu {
  .navbar-profile-name .avatar {
    min-width: 65px;
    min-height: 65px;
  }

  .navbar-profile-dropdown {
    .mat-expansion-panel-header,
    .mat-expansion-panel-header:is(:hover, :focus) {
      height: auto !important;
      background: $bg-light;
      border-bottom: 1px solid $border-color-1;
      padding: 1rem;
    }

    .mat-expansion-panel-body {
      padding: 0;

      > div {
        border-bottom: 1px solid $border-color-1;
        padding-inline: 1rem;
      }
    }

    .mat-expansion-panel-content {
      max-height: calc(100vh - 500px);
      overflow: auto;
    }

    .mat-menu-item {
      font-size: 1rem;
      font-weight: $fw-bold;
      height: 50px;
      line-height: 50px;
    }

    .avatar :is(figure, figcaption) {
      width: 50px;
      min-width: 50px;
      height: 50px;
      min-height: 50px;
      list-style: 50px;
    }
  }
}

.prevent-select {
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  /* Standard syntax */
}

@include ratio-125 {
  .p-5 {
    padding: calc(3rem - #{$ratio-125-value} - 0.2rem) !important;
  }
}

wr-bottom-additional-menu {
  svg {
    path,
    circle {
      fill: $primary;
    }
  }
}

.mobile-menu {
  &.mobile-menu-case {
    justify-content: initial;
    align-items: center;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    background: $primary;
    top: 59px;
    height: 60px;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
    box-shadow: none !important;
  }

  .mobile-menu__btn {
    flex-grow: 1;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    gap: 4px;
  }

  .mobile-menu__text {
    font-weight: 600;
    font-size: 8px;
    text-transform: uppercase;
    color: #7f7f7f;
  }

  &.mobile-menu-case-bottom {
    background: $primary !important;

    mat-icon,
    span {
      color: #fff !important;
    }
  }
}

wr-panel {
  height: calc(100vh - 310px);
  display: block;
  position: relative;
  display: flex;
  padding-top: 85px;
  box-sizing: border-box;

  @include respond(phone) {
    padding-top: 0;
  }

  .text-center {
    margin: auto;
  }

  .step-count {
    line-height: 30px;
  }
}

.self-assessment-width {
  max-width: 75%;

  @include respond(phone) {
    max-width: 100%;
  }
}

.wizard.wizard-workflow {
  position: absolute;
  top: 0;

  li:not(:last-child):after {
    width: 98%;
    height: 2px;
  }

  li:not(:first-child):before {
    height: 2px;
  }

  .done,
  .active {
    .wizard-icon {
      background-color: $white;
      color: $primary;
    }
  }

  .done {
    .wizard-text {
      color: $text-dark;
    }
  }

  .active {
    position: relative;

    .wizard-icon {
      position: relative;
      background: $primary;
      color: $white;
      outline: 4px solid #fff;
      box-shadow: 0 0 0px 6px $primary;
    }

    &:after {
      // content: "";
      // width: 40px;
      // height: 40px;
      // border-radius: 50%;
      // border: 2px solid $primary;
      // position: absolute;
      // top: 16px;
      // left: 50%;
      // transform: translate(-50%, -50%);
      // background: #fff;
      // z-index: 2;
    }
  }

  .wizard-icon {
    background: #ebebeb;
    border-color: #ebebeb;
    color: #cacaca;
    border-width: 2px !important;
    font-weight: 500;

    .mat-icon {
      background: $primary;
      color: #fff;
      width: 14px;
      height: 14px;
      border-radius: 50%;
      line-height: 15px;
      font-size: 14px;
      position: absolute;
      left: calc(50% - 7px);
      bottom: -8px;
    }
  }
}

.hr-role-remove-container {
  overflow: auto;
  max-height: 60vh;
}

.form-disabled {
  pointer-events: none;
}

@media not all and (min-resolution: 0.001dpcm) {
  // .app-sidenav__container .sidenav__container .mat-sidenav-content {
  //   top: 0 !important;
  //   height: 99.9vh !important;
  // }
}

.menu-opened {
  .app__container.authorized-view {
    overflow: auto;
    margin: $app-header-height 0 0 0;
    height: calc(100% - #{$app-header-height});

    @media (max-width: $breakpoint--mobile) {
      height: calc(100vh - 120px);
      margin: $app-header-height 0 0 0;
    }
  }
}

.menu-closed {
  .app__container.authorized-view {
    overflow: auto;
    margin: $app-header-height 0 0 $closed-nav-width;
    height: calc(100% - #{$app-header-height});

    @media (max-width: $breakpoint--mobile) {
      height: calc(100vh - 120px);
      margin: $app-header-height 0 0 0;
    }
  }
}

.setup {
  &-icon {
    font-size: 60px;
    width: 100px !important;
    height: 100px !important;
    line-height: 100px;
    background-color: $gray-3;
    border-radius: 100%;
  }

  &-done {
    position: absolute;
    bottom: 6px;
    right: -10px;
    background: $orange-1;
    border-radius: 50%;
    width: 30px !important;
    height: 30px !important;
    z-index: 2;
    line-height: 30px;
    font-size: 20px;
    border: 3px solid #fff;
  }
}

.org {
  &-tree-container {
    display: inline-block;
    width: 100%;
  }

  &-head {
    &:after {
      content: '';
      position: absolute;
      top: 100%;
      height: 20px;
      left: 50%;
      border-left: 2px dotted $gray-3;
    }
  }

  &-department {
    position: relative;
    overflow-x: auto;
    display: flex;
    justify-content: center;
    width: 100%;

    &:before {
      content: '';
      position: absolute;
      width: 20px;
      right: 0;
      top: -2px;
      height: 100%;
      background-color: #fff;
      z-index: 1;
    }

    &:after {
      content: '';
      position: absolute;
      border-left: 2px dotted $gray-3;
      left: 0;
      top: 0;
      height: calc(100% - 200px);
    }
  }
}

.add-org-member {
  width: 120px;
  height: 120px;
  border-radius: $border-radius;
  position: relative;
  color: $primary;
  transition: 0.2s ease-in-out;
  // background: rgba($primary, 0.2);
  border: 2px dotted $gray-3;
  box-sizing: border-box;
  display: inline-block;
  margin-left: 2px;

  &.required {
    background: $primary;
    color: #fff;
    border: 2px solid $primary;

    &:hover {
      background: $primary-dark;
    }
  }

  &.filled {
    border: 1px solid $primary;
  }

  &.readonly {
    border: 1px solid $gray-3;
  }

  &:hover {
    .add-org-icon:after {
      transform: translate(-50%, -50%) scale(2);
      transform-origin: center;
    }
  }

  &:not(.org-head) {
    // &:after {
    //   content: "";
    //   height: 20px;
    //   border-left: 2px dotted $gray-3;
    //   position: absolute;
    //   bottom: 100%;
    //   left: 50%;
    // }

    + .add-org-member {
      // &:before {
      //   content: "";
      //   height: 1px;
      //   width: calc(100% + 40px);
      //   border-top: 2px dotted $gray-3;
      //   position: absolute;
      //   bottom: calc(100% + 18px);
      //   right: 50%;
      // }

      // &:first-child {
      //   &:before {
      //     left: 50%;
      //     right: initial;
      //   }
      // }
    }
  }

  &.disabled {
    pointer-events: none;
    background: rgba($primary, 0.2);
    border: 0;

    .add-org-icon {
      opacity: 0;
    }
  }
}

.org-member-filled {
  box-sizing: border-box;
  color: $text-dark;
}

.add-org-icon {
  position: relative;
  width: 100%;
  height: 100%;
  display: block;
  transition: 0.2s ease-in-out;
  cursor: pointer;
  box-sizing: border-box;

  &:not(.add-org-icon-filled):after {
    content: '+';
    font-size: 32px;
    line-height: 32px;
    display: block;
    width: 32px;
    height: 32px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }
}

.add-org-member:hover .remove-org-member {
  display: block !important;
}

.remove-org-member {
  position: absolute;
  top: -12px;
  right: -12px;
  border-radius: 50%;
  color: #666;
  background: #ededed;
  padding: 4px;
  font-size: 16px;
  display: none;
  z-index: 5;

  &:hover {
    color: #fff;
    background: $danger;
  }
}

.mat-tooltip {
  white-space: pre-line;
}

.success-notification-overlay,
.warning-notification-overlay,
.error-notification-overlay {
  border-left: 4px solid;

  &::before {
    font-family: 'Material Symbols Outlined';
    float: left;
    font-size: 24px;
    padding-right: 10px;
    // because we want spaces to be considered in content
    // https://stackoverflow.com/questions/5467605/add-a-space-after-an-element-using-after
    white-space: pre;
  }
}

.success-notification-overlay {
  border-left-color: $primary;
  background-color: rgba($primary, 0.9);

  &::before {
    content: '\e86c';
    color: $white;
  }
}

.warning-notification-overlay {
  border-left-color: $orange-1;
  background-color: rgba($orange-1, 0.9);

  &::before {
    content: '\e002';
    color: $white;
  }
}

.error-notification-overlay {
  border-left-color: $danger;
  background-color: rgba($danger, 0.9);

  &::before {
    content: '\e000';
    color: $white;
  }
}

.bg-light {
  &-orange,
  &-warning {
    background: rgba($orange, 0.1);
  }

  &-primary {
    background: rgba($primary, 0.1);
  }

  &-danger {
    background: rgba($danger, 0.1);
  }

  &-blue {
    background: rgba($blue, 0.1);
  }
}

.text-blue {
  color: $blue !important;
}

.icon {
  &-48 {
    font-size: 48px !important;
    line-height: 48px;
  }
}

.text-truncate-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.mat-icon {
  overflow: visible !important;
}
.mat-select,
.mat-select-trigger {
  height: 100% !important ;
}
popover-container {
  z-index: 99;
}
.mat-horizontal-stepper-wrapper {
  flex-direction: column-reverse !important;
}
