wr-login {
  wr-lang-selector {
    justify-content: flex-end;
    text-align: right;
    margin-top: -15px;
    display: block;
  }

  .mat-checkbox .mat-checkbox-layout .mat-checkbox-label {
    white-space: nowrap;
  }
}

.entry__container {
  margin-top: 0;
  height: auto;
  min-height: calc(100vh - (48px * 2));
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 48px 0;
  background-color: $primary;
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  /* Standard syntax */
  // @media (max-width:$breakpoint--small-screen ) {
  //   margin-top: 64px;
  // }
}

.wr-entry-card-full {
  .mat-card.entry__card {
    width: 80vw;
  }

  .entry__container-scroll {
    max-height: calc(100vh - 510px);
    padding: 1rem;
    overflow: auto;

    p {
      word-break: normal;
      font-size: 14px !important;
    }
  }

  .mat-card-content {
    margin-bottom: 0;
  }

  wr-lang-selector>div {
    text-align: right;
  }
}

.wr-entry-card-consent wr-lang-selector>div {
  text-align: right;
}

.terms-conditions {
  h3 {
    font-weight: 600 !important;
    font-size: medium !important;
  }

  h4,
  h5,
  h6,
  li,
  p {
    font-weight: 400 !important;
    font-size: small !important;
  }

  a {
    font-size: small !important;
  }
}