@import 'theme';
@import 'mixins';

input,
textarea,
select {
  &:-webkit-autofill {
    &,
    &:hover,
    &:focus,
    &:first-line {
      @include font-style(300, 15px, normal, $text-light, auto);
      -webkit-text-fill-color: $text-light;
      -webkit-box-shadow: 0 0 0 30px $background-2 inset !important;
      font-family: 'Montserrat', sans-serif;
    }
  }
}
