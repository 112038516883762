.mat-tab-body-content {
  padding: 20px;
}

.mat-tab-body.mat-tab-body-active {
  overflow: visible !important;
}

.mat-tab-header-center {
  .mat-tab-labels {
    justify-content: center !important;
  }
}

.mat-tab-body.mat-tab-body-active {
  width: 100% !important;
}