@import 'theme';
@import 'mixins';

// @import 'angular-material
* {
  font-family: 'Montserrat', sans-serif;
}

// h1 {
//   @include font-style(500, 32px, normal, $text-light, 50x);
// }

h1 {
  @include font-style($fw-bold, $fs-xl, normal, $text-light, 1.6);
}

h2 {
  @include font-style($fw-regular, $fs-lg, normal, $text-light, 1.6);
}

h3 {
  @include font-style($fw-regular, calc($fs-base + 2px), normal, $text-light, 1.6);
}

h4 {
  @include font-style($fw-regular, $fs-base, normal, $text-light, 1.6);
}

h5 {
  @include font-style($fw-regular, $fs-sm, normal, $text-light, 1.6);
}

a {
  @include font-style($fw-regular, 16px, normal, $green-1, 1.6);

  text-decoration: none;
  cursor: pointer;

  // &:hover {
  //   font-weight: $fw-ew !important;
  // }
}

.text--lead {
  @include font-style(normal, 17px, normal, $text-dark, 1.6);
}

p,
.text--regular {
  @include font-style($fw-regular, 16px, normal, $text-light, 1.6);
}

.text--small {
  @include font-style($fw-regular, 14px, normal, $text-light, 1.6);

  a {
    @include font-style($fw-regular, 14px, normal, $green-1, 1.6);
  }
}

.material-icons {
  font-family: 'Material Symbols Outlined';
}

// .material-icons.filled {
//   font-variation-settings: 'FILL' 1, 'wght' 400, 'GRAD' 0, 'opsz' 48;
// }

.fs-smaller {
  font-size: 12px !important;
}

@include ratio-125 {

  h1 {
    @include font-style($fw-bold, calc(#{$fs-xl} - calc((#{$fs-xl} * #{$ratio-125-value-percentage})/100)), normal, $text-light, 1.6);
  }

  h2 {
    @include font-style($fw-regular, calc(#{$fs-lg} - calc((#{$fs-lg} * #{$ratio-125-value-percentage})/100)), normal, $text-light, 1.6);
  }

  h3 {
    @include font-style($fw-regular, calc(#{$fs-base} - calc((#{$fs-base} * #{$ratio-125-value-percentage})/90)), normal, $text-light, 1.6);
  }

  h4 {
    @include font-style($fw-regular, calc(#{$fs-base} - calc((#{$fs-base} * #{$ratio-125-value-percentage})/100)), normal, $text-light, 1.6);
  }

  h5 {
    @include font-style($fw-regular, calc(#{$fs-sm} - calc((#{$fs-sm} * #{$ratio-125-value-percentage})/100)), normal, $text-light, 1.6);
  }

  a {
    @include font-style($fw-regular, 14px, normal, $green-1, 1.6);

    text-decoration: none;
    cursor: pointer;

    // &:hover {
    //   font-weight: $fw-ew !important;
    // }
  }

  .text--lead {
    @include font-style(normal, 15px, normal, $text-dark, 1.6);
  }

  p,
  .text--regular {
    @include font-style($fw-regular, calc(#{$fs-base} - calc((#{$fs-base} * #{$ratio-125-value-percentage})/100)), normal, $text-light, 1.6);
  }

  $fs-ratio-125_fs-14: calc(14px - calc((14px * #{$ratio-125-value-percentage})/100));

  .text--small {
    @include font-style($fw-regular, $fs-ratio-125_fs-14, normal, $text-light, 1.6);

    a {
      @include font-style($fw-regular, $fs-ratio-125_fs-14, normal, $green-1, 1.6);
    }
  }

  .small {
    font-size: $fs-ratio-125_fs-14 !important;
  }


  .mat-typography {
    .fs-4 {
      font-size: calc(1.275rem + 0.3vw - #{$ratio-125-value}) !important;
    }

    .fs-5 {
      @include fs_ratio-125(1.25rem);
    }
  }

  .mat-card-subtitle,
  .mat-card-content {
    @include fs_ratio-125(14px);
  }
}

.text-primary-dark {
  color: $primary-dark;
}