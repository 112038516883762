.message-center-conversation-sender__form-field {
  &.mat-form-field .mat-form-field-flex {
    border-radius: 45px !important;
  }
}

.message-category {
  font-size: 12px;
  padding: 0.5em 1em;
  border-top: 1px solid $border-color-1;
  // border-bottom: 1px solid $border-color-1;
  position: sticky;
  top: 0;
  background: #fff;
  z-index: 9;
  color: $primary;
  font-weight: $fw-bold;
  text-transform: uppercase;
}

@include respond(phone) {
  wr-message-center-channels-list {
    max-height: initial !important;
    height: calc(100vh - 295px) !important;
  }

  .message-center {
    &-page-cards {
      position: relative;
      height: calc(100vh - 238px) !important;
      overflow: hidden;
      padding: 0 !important;

      .card-channels {
        min-width: 100% !important;
        max-width: 100% !important;
      }
    }

    &-conversation-title {
      .mat-icon {
        float: left;
        margin: 2px 5px 0 0;
      }
    }
  }

  .card {
    &-conversation {
      position: absolute;
      top: 0;
      right: -100%;
      z-index: 19;
      transition: all 0.3s;
      background-color: #fff;

      &.card-conversation-active {
        transform: translate3d(-100%, 0, 0);
        animation-timing-function: 1s ease-in;
      }
    }
  }
}
